import { RouteNames } from '@/routes/router.types'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { getOrCreateUniformResidentialLoanApplication } from '@/services/api'
import { StatusCodes } from 'http-status-codes'

export enum EmploymentType {
    employed = 'employed',
    selfEmployed = 'selfEmployed',
    retired = 'retired',
    other = 'other',
}

export enum PastEmploymentType {
    employed = 'employed',
    selfEmployed = 'selfEmployed',
    retired = 'retired',
    unemployed = 'unemployed',
}

export enum MaritalStatus {
    MARRIED = 'MARRIED',
    UNMARRIED = 'UNMARRIED',
    SEPARATED = 'SEPARATED',
}

export const OtherIncomeType = {
    Alimony: 'Alimony',
    AutomobileAllowance: 'Automobile Allowance',
    BoarderIncome: 'Boarder Income',
    CapitalGains: 'Capital Gains',
    ChildSupport: 'Child Support',
    DefinedContributionPlan: 'Defined Contribution Plan',
    Disability: 'Disability',
    DividendsInterest: 'Dividends Interest',
    FosterCare: 'Foster Care',
    HousingAllowance: 'Housing Allowance',
    MortgageCreditCertificate: 'Mortgage Credit Certificate',
    MortgageDifferential: 'Mortgage Differential',
    NotesReceivableInstallment: 'Notes Receivable Installment',
    Pension: 'Pension',
    PublicAssistance: 'Public Assistance',
    Royalties: 'Royalties',
    SeparateMaintenance: 'Separate Maintenance',
    SocialSecurity: 'Social Security',
    Trust: 'Trust',
    Unemployment: 'Unemployment',
    VABenefitsNonEducational: 'VA Benefits Non-Educational',
}

export type Mismo34OtherIncomeType = keyof typeof OtherIncomeType | 'Other'

export enum Mismo34AssetType {
    CheckingAccount = 'CheckingAccount',
    Stock = 'Stock',
    RetirementFund = 'RetirementFund',
    LifeInsurance = 'LifeInsurance',
}

export const AssetType = {
    [Mismo34AssetType.CheckingAccount]: 'Bank Account',
    [Mismo34AssetType.Stock]: 'Stocks, Bonds, and Mutual Funds',
    [Mismo34AssetType.RetirementFund]: 'Retirement Fund',
    [Mismo34AssetType.LifeInsurance]: 'Life Insurance',
}

export const LiabilityType = {
    Installment: 'Installment',
    LeasePayment: 'Lease Payment',
    Open30DayChargeAccount: 'Open 30-Day Charge Account',
    Revolving: 'Revolving',
    HELOC: 'HELOC',
    MortgageLoan: 'Mortgage Loan',
    Alimony: 'Alimony',
    ChildSupport: 'Child Support',
    JobRelatedExpenses: 'Job Related Expenses',
    SeparateMaintenanceExpense: 'Separate Maintenance Expense',
}

export type Mismo34LiabilityType = keyof typeof LiabilityType | 'Other'

export const uniformResidentialLoanApplicationErrors: { [key: string]: string } = {
    serverError: 'Looks like something went wrong. Please try again momentarily.',
    invalidDateRange: 'Start date cannot be after the end date.',
}

export enum UniformResidentialLoanApplicantType {
    primary = 'primary',
    coApplicant = 'coApplicant',
}

export const uniformResidentialLoanApplicationStatusToRouteName: { [key: string]: RouteNames } = {
    // Status names come from enum UniformResidentialLoanApplicationStatus in aven_backend/src/entity/uniformResidentialLoanApplication.types.ts
    maritalStatus: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_MARITAL_STATUS,
    addressHistory: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_ADDRESS_HISTORY,
    dependents: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DEPENDENTS,
    properties: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PROPERTIES,
    currentEmployment: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_EMPLOYMENT,
    currentIncome: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_INCOME,
    pastEmployment: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PAST_EMPLOYMENT,
    otherIncome: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_INCOME,
    otherAssets: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_ASSETS,
    liabilities: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_LIABILITIES,
    declarationQuestions: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DECLARATION_QUESTIONS,
    hmda: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_HMDA,
    confirmation: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CONFIRMATION,
    brokerageAgreement: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_BROKERAGE_AGREEMENT,
    submitted: RouteNames.UNIFORM_RESIDENTIAL_LOAN_APPLICATION_SUBMITTED,
}

export const CONFIRMATION_STATUS = 'confirmation'

export const getUniformResidentialLoanApplication = async (forceRefresh = false) => {
    let uniformResidentialLoanApplication = appSessionStorage.getItem(sessionStorageKey.uniformResidentialLoanApplication)
    let uniformResidentialLoanApplicants = appSessionStorage.getItem(sessionStorageKey.uniformResidentialLoanApplicants)
    if (uniformResidentialLoanApplication && uniformResidentialLoanApplicants && !forceRefresh) {
        return { uniformResidentialLoanApplication: JSON.parse(uniformResidentialLoanApplication), uniformResidentialLoanApplicants: JSON.parse(uniformResidentialLoanApplicants) }
    }
    const response = await getOrCreateUniformResidentialLoanApplication()
    if (response.status === StatusCodes.OK && response.data.success) {
        uniformResidentialLoanApplication = response.data.payload.uniformResidentialLoanApplication
        uniformResidentialLoanApplicants = response.data.payload.uniformResidentialLoanApplicants
        appSessionStorage.setItem(sessionStorageKey.uniformResidentialLoanApplication, JSON.stringify(uniformResidentialLoanApplication))
        appSessionStorage.setItem(sessionStorageKey.uniformResidentialLoanApplicants, JSON.stringify(uniformResidentialLoanApplicants))
        return { uniformResidentialLoanApplication, uniformResidentialLoanApplicants }
    }
}

export const getPrimaryUniformResidentialLoanApplicant = async () => {
    const uniformResidentialLoanApplicationData = await getUniformResidentialLoanApplication()
    return uniformResidentialLoanApplicationData?.uniformResidentialLoanApplicants?.find(
        (uniformResidentialLoanApplicant) => uniformResidentialLoanApplicant.type === UniformResidentialLoanApplicantType.primary
    )
}
