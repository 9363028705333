// @ts-ignore
import { contact_info } from '@/utils/contact-info-dictionary'
import {
    MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS,
    MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT,
    MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS,
    MIN_DAYS_BETWEEN_PAYMENT_AND_CASH_OUT,
    MIN_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS,
    PAYMENT_AMOUNT_BEFORE_BLOCKING_CASH_OUT,
} from '@/data/constants'
import generic from '@/utils/generic'
import { toFormattedUSDStripTrailingZeroCents } from '@/mixins/format'

export const ENGLISH_TRANSLATIONS = {
    pages: {
        origination: {
            hmda: {
                title: 'To ensure fair lending, federal law requires us to ask the following. This information is <b>NOT</b> used for our application decision. You can decline to provide this info.',
                skip: "<u>Skip, I Don't Wish To Provide</u>",
                loading: 'Saving...',
                skipCta: "Skip, I Don't Wish To Provide",
                cta: 'Continue',
                dontProvide: "I don't wish to provide",
                info: '<p>The purpose of collecting this information is to help ensure that all applicants are treated fairly and that the housing needs of communities and neighborhoods are being fulfilled. For residential mortgage lending, Federal law requires that we ask applicants for their demographic information (ethnicity, race, and sex) in order to monitor our compliance with equal credit opportunity, fair housing, and home mortgage disclosure laws. You are not required to provide this information, but are encouraged to do so. You may select one or more designations for “Ethnicity” and one or more designations for “Race". The law provides that we may not discriminate on the basis of this information, or on whether you choose to provide it. However, if you choose not to provide the information and you have made this application in person, Federal regulations require us to note your ethnicity, race, and sex on the basis of visual observation or surname. If you do not wish to provide some or all of this information, please check below.</p><p>Not available for home purchase.</p>',
                statedUsageDisclaimer: 'Not available for purchase.',
                sex: {
                    placeholder: 'Sex',
                    male: 'Male',
                    female: 'Female',
                },
                usage: {
                    title: 'Usage',
                    homeImprovement: 'Home Improvement',
                    balanceTransfer: 'Balance Transfer',
                    other: 'Other',
                    disclaimer: 'Note: Answer from earlier in the application. Not available for home purchase.',
                },
                ethnicity: {
                    placeholder: 'Ethnicity',
                    maxEthnicitiesError: 'Enter no more than {numMaxEthnicities} ethnicities.',
                    hispanic: {
                        placeholder: 'Hispanic/Latino',
                        mexican: 'Mexican',
                        puertoRican: 'Puerto Rican',
                        cuban: 'Cuban',
                        other: {
                            placeholder: 'Other Hispanic or Latino',
                            hint: 'Ex. Ecuadorian',
                        },
                    },
                    notHispanic: 'Not Hispanic/Latino',
                },
                race: {
                    placeholder: 'Race',
                    maxRacesError: 'Enter no more than {numMaxRaces} races.',
                    americanIndian: {
                        placeholder: 'American Indian or Alaska Native',
                        hint: 'What is your principal tribe?',
                    },
                    asian: {
                        placeholder: 'Asian',
                        asianIndian: 'Asian Indian',
                        chinese: 'Chinese',
                        filipino: 'Filipino',
                        japanese: 'Japanese',
                        korean: 'Korean',
                        vietnamese: 'Vietnamese',
                        other: {
                            placeholder: 'Other Asian',
                            hint: 'Ex. Taiwanese',
                        },
                    },
                    black: 'Black or African American',
                    hawaiian: {
                        placeholder: 'Native Hawaiian/Pacific Islander',
                        native: 'Native Hawaiian',
                        guamanian: 'Guamanian or Chamorro',
                        samoan: 'Samoan',
                        other: {
                            placeholder: 'Other Pacific Islander',
                            hint: 'Ex. Tongan',
                        },
                    },
                    white: 'White',
                },
            },
        },
        reinstateCreditLimit: {
            title: 'Reinstate Credit Limit',
        },
        aprReduction: {
            title: 'Lower APR',
        },
        documentsAndNotices: {
            title: 'Documents',
            webViewError: 'Your documents are available on our website. Please log in and go to “Account” to view and download your tax documents.',
            webViewErrorCta: 'Go To Website',
            noDocuments: "You don't have any documents currently available.",
        },
        statements: {
            title: 'Statements',
            remainingStatementBalance: 'Remaining Statement Balance',
            minimumDue: 'Minimum Due',
            due: 'Due {date}',
            pastDue: 'Past Due',
            paymentDueBy: 'Due by',
            scheduledOn: 'Scheduled For',
            nextStatement: 'Next Statement {date}',
            autoPay: 'AutoPay',
            paymentOptionsTitle: 'Make a Payment',
            makeAPayment: 'Make A Payment',
            payNow: 'Pay Now',
            setUpAutoPay: 'Set Up AutoPay',
            minimumPaid: 'Minimum Paid',
            nothingDueNow: 'You do not have anything due. Your next statement will be available on {date}',
            nothingCurrentlyDue: 'Nothing Currently Due',
            FullBalance: 'Full Balance',
            StatementBalance: 'Statement Balance',
            Minimum: 'Minimum',
            CustomAmount: 'Enter an Amount',
            Cancel: 'Cancel',
            paidOn: 'Paid on',
            FundingSourceTitle: 'No Funding Source',
            FundingSource: 'In order to make a payment, please add a funding source, like a bank account or debit card.',
            FundingSourceButton: 'Add Funding Source',
            viewStatementError: {
                title: 'Error',
                description: `Couldn't load statement. Please contact support at ${contact_info.dashFormatted}`,
                unavailable: 'Your {name} statement PDF is still being prepared. Please try again in a few hours.',
            },
        },
        activity: {
            title: 'Activity',
            Available: '{openToBuyAmount} Available',
            fastFundingAvailable: '{openToBuyAmount} Early Access Available',
            fastFundingAvailablePendingReview: '{proposedFastFundingCreditLimit} Early Access (Pending)<br/>ETA {eta}',
            CurrentBalance: 'Current Balance',
            NoTransactionYet: 'No Activity Yet',
            points: '{formattedPoints} point | {formattedPoints} points',
            AnticipationHtml: 'In the meantime, you can schedule a <br/>balance transfer from the account tab.',
            makePayment: 'swipe your card to start',
            Today: 'Today',
            Pending: 'Pending',
            Upcoming: 'Upcoming',
            autoPay: 'AutoPay',
            scheduledPayment: 'Scheduled Payment',
            requestHigherCreditLimitTitle: 'Request Higher Credit Limit',
            requestHigherCreditLimitDescription: 'Check if you qualify for a higher credit limit in seconds.',
            requestHigherCreditLimitButton: 'Check Now',
            requiredLinkBankAccount: 'Required: Link Bank Account',
            cliRequiredLinkBankAccountDescription: 'Link your bank account by 11/30 to finalize your cash out and higher limit.',
            requiredLinkBankAccountDescription: 'Link your bank account by 11/30 to finalize your cash out.',
        },
        requestFullCreditLimit: {
            title: 'Unlock {fullCreditLimit} Limit',
            prompt: 'Would you like to request the full credit limit of {fullCreditLimit}? Our team will review and update your account once approved.',
            button: 'Request Full Credit Limit',
            requestApproved: `Your request to get your full credit limit has been approved. Your credit limit has increased to {fullCreditLimit}.`,
            requestDenied:
                'It looks like you need to payoff or remove one of the liens listed below. Confirm with the lien holder that it was removed, and then allow 14 days for the change to be reflected on your credit profile before trying again.',
            done: 'Done',
            gotIt: 'Got It',
            error: 'An unexpected error has occurred. Please try again later.',
        },
        WhatIsDue: {
            whatIsDue: 'What Is Due',
            nothingDue: 'Nothing Due',
            statementBalance: 'Statement Balance',
            minimumDue: 'Minimum Due',
            dqMinimumDue: 'Minimum Due (Incl. Past Due)',
            dueBy: 'Due By',
            remainingStatementBalance: 'Remaining Statement Balance',
            nextStatementDate: 'Next Statement Date',
            payNow: 'Pay Now',
            makePayment: 'Make Payment',
            details: 'Details',
            nextAutopayPayment: `Your $\{autopayAmount} Auto Pay payment is set for {formattedAutopayDate}.`,
            nextAutopayPaymentNoAmount: `Your Auto Pay payment is set for {formattedAutopayDate}.`,
            nextScheduledPayment: `Your $\{scheduledPaymentAmount} scheduled payment is set for {formattedPaymentDate}.`,
            banner: {
                mainContent: `You are {amountPastDue} past due. Pay now to get current.`,
                payNow: `Pay Now`,
            },
        },
        PaymentBreakdown: {
            minimumDue: 'Minimum Due',
            purchases: 'Purchases / Revolving',
            interest: 'Interest',
            interestExcludingFixed: 'Interest (excl. Fixed Plans)',
            fees: 'Fees',
            fixedMonthlyPayments: 'FIXED MONTHLY PAYMENTS',
            fixedPlanPrincipal: 'Principal Repayment',
            fixedPlanInterest: 'Interest',
            fixedFinanceCharge: 'Fixed Finance Charge',
            stateRecordingCharge: 'State Recording Charge',
            totalMinimumDue: 'Total Minimum Due',
        },
        AutoPay: {
            SetupAutoPay: 'Setup AutoPay',
            UpdateAutoPay: 'AutoPay',
        },
        SetupAutoPay: {
            description: 'Set up AutoPay for peace of mind and to keep your 0.25%&nbsp;discount',
            descriptionWithBenefits: 'Get rewards, avoid late fees and keep your lower APR by setting up minimum AutoPay',
            benefitsTitle: 'AutoPay Benefits',
            benefitsSubtitle1: 'Get {cashBackPercent} Unlimited Cashback',
            benefitsSubtitle2: 'Keep .25% APR Discount',
            setupAutoPay: 'Set Up AutoPay',
            notNow: 'Not Now',
            getCashBackAndDiscount: 'Get Cashback & Discount',
            noCashBackOrDiscount: 'No Cashback or Discount',
            Promo: {
                SetupCredit: 'Setup AutoPay to receive $25 in statement credit. Available for the next 7 days.',
                SetupCreditDetails: 'Aven will apply a one-time statement credit of $25 if AutoPay is turned on at the time any of your next two statements are generated.',
            },
        },
        MakePayment: {
            MakePayment: 'Make A Payment',
            PayNow: 'Pay {amount}',
            TurnOnAutoPay: 'Turn On AutoPay',
            TurnOffAutoPay: 'Turn Off AutoPay',
            UpdateAutoPay: 'Update AutoPay',
            Pay: 'Pay',
            Amount: 'Amount',
            ConfirmAmount: 'Type "{amount}" to confirm amount',
            ConfirmAmountValidationMessage: 'Please enter {amount} to confirm the amount. If it is not correct, please go back to update.',
            PleaseNote: 'PLEASE NOTE',
            GetPaymentSourcesError: 'There was an issue trying to retrieve your payment sources. Please refresh and try again.',
            AddPaymentSourceError: 'There was an issue trying to add the payment. Please try again.',
            PaymentAuthorization: 'Payment Authorization',
            legalText1:
                'If you make your payment before 7:59 PM (EST) we will initiate the payment on the same day, but it might take a few days to have the funds withdrawn from your account and your available balance to be updated.',
            legalText2:
                'By clicking “Pay Now’ you authorize a one time payment of ${amount} on {date} from {source}. You can print or save the confirmation screen for a receipt of the scheduled payment.',
            PaymentSuccessTitle: 'Payment Successful',
            PaymentSuccessContent: 'Thank you for your payment of ${amount} from {source}. Your payment will be reflected in your available credit by {otbReleaseDate}.',
            PaymentSuccessButton: 'Done',
            ProcessingPayment: 'Processing Payment',
            ProcessingAutoPay: 'Setting up AutoPay',
            Loading: 'Loading',
            oneTime: 'One Time',
            autoPay: 'AutoPay',
            MinimumDueLabel: 'Minimum Due',
            StatementBalanceLabel: 'Statement Balance',
            CurrentBalanceLabel: 'Current Balance',
            PastDueLabel: 'Past Due',
            FixedAmountLabel: 'Fixed Amount',
            OtherAmountLabel: 'Other Amount',
            OtherAmountsLabel: 'Other Amounts',
            PayExtraAmountTo: 'Pay Extra Amount To',
            HighestAPRFirst: 'Highest APR First',
            HighestAPRFirstExplanation: 'Amounts above the minimum due will pay back your highest APR balances first.',
            CreditCardBalanceFirst: 'Credit Card Balance First',
            CreditCardBalanceFirstExplanation: 'Amounts above the minimum due will pay back your variable APR balance first.',
            PayFrom: 'Pay From',
            PayOn: 'Pay On',
            AddNewAccount: 'Add New Account',
            AddNewBankAccount: '+ Add New Bank Account',
            AddNewDebitCard: '+ Add New Debit Card',
            BankAccount: 'Bank Account',
            DebitCard: 'Debit Card',
            SecondaryAutoPayEdit: 'To edit AutoPay, please have the primary cardholder login to make the change. If you need to make a payment, you can make a one-time payment using the tab above.',
            SecondaryAutoPayAdd: 'To turn on AutoPay, please have the primary cardholder login to make the change. If you need to make a payment, you can make a one-time payment using the tab above.',
            AddBankError: 'There was an error adding your bank account. Please check your routing and account number and try again.',
            GenericError: 'Something went wrong. Please try again.',
            DuplicatePayment: 'Sorry, you have already made a payment of the same amount recently. Please double check that this is not a duplicate payment.',
            AuthorizationDeclined: 'Bank account authorization declined. Please retry your payment using another payment source.',
            DebitCardInsufficientFunds: 'Payment declined due to insufficient funds.  Please retry your payment for a lower amount or use a different payment source.',
            DebitCardInvalidNumber: 'Debit card payment declined due to an invalid card number. Please retry your payment using another payment source.',
            DebitCardWithdrawalLimit: 'Debit card payment declined due to a withdrawal limit at your bank. Please retry your payment for a lower amount or use a different payment source.',
            DebitCardInvalidAmount: 'Debit card payment declined due to a transaction limit at your bank. Please retry your payment for a lower amount or use a different payment source.',
            terms1: 'If you make your payment before 7:59 PM (EST) we will reflect the payment on your account today, but you might not see your available balance change for up to 3 days.',
            terms2: 'By clicking “Confirm Payment“ you authorize a one time payment of {amount} on {date} from {source}. You can print or save the confirmation screen for a receipt of the scheduled payment.',
            autoPayDisabledConfirmation: {
                title: 'Turn Off AutoPay',
                subTitle1: 'Are you sure you want to turn off automatic payments? Any future or scheduled automatic payments will be canceled and not happen. Be sure to make payments manually.',
                warning: 'Turning AutoPay off will result in your cashback going from {cashBackPercent} to {cashBackPercentAfterDisableAutoPay} and removal of your 0.25% APR discount.',
                keepOnBtnCta: 'Keep AutoPay On',
                turnOffBtnCta: 'Confirm Turn Off AutoPay',
            },
            autopayStatementBalanceWarning: 'Note: Your current statement balance is {currentStatementBalance}.',
            oneTimePayment: {
                confirmAmount: 'A payment of <span class="fw-bold">{paymentAmount}</span> will be withdrawn from your account ending in {paymentSourceLast4}',
            },
            Frequency: 'Frequency',
            autoPayRadioButtonText: 'Recurring (AutoPay)',
            autoPayNoPaymentRequired: 'Payment not required at this time',
            autoPayAccountPastDue: `Account past due`,
            oneTimeRadioButtonText: 'One Time Payment',
        },
        PaymentSuccessConfirmation: {
            title: 'Payment Successful',
            scheduledPaymentTitle: `Payment Scheduled`,
            subTitle: 'Thank you for your payment of {paidAmount} from {source}.',
            coreCardErrorSubTitle: 'Thank you for your payment of {paidAmount} from {source}. It is submitted and will be posted to your account within 2 business days.',
            otbReleaseDateSubtitle: ' Your payment will be reflected in your available credit by {otbReleaseDate}.',
            scheduledPaymentSubtitle: 'Thank you for your scheduled payment of {amountScheduledToBePaid} from {source}. Your payment will be made on {paymentDate}.',
            doneCta: 'Done',
        },
        AutoPaySuccessConfirmation: {
            title: 'AutoPay Turned On',
            subTitleMinimumDue: 'Thank you for turning on AutoPay. The Minimum Due will automatically be deducted from {source} on the due date.',
            subTitleStatementBalance: 'Thank you for turning on AutoPay. The Statement Balance will automatically be deducted from {source} on the due date.',
            subTitleFixedAmount: 'Thank you for turning on AutoPay. {amount} will automatically be deducted from {source} on the due date.',
            doneCta: 'Done',
        },
        WireTransferInstructions: {
            title: 'Wire Transfer',
            instructions: 'Please contact your bank to initiate a wire transfer using these details',
            routingNumber: 'Routing Number',
            accountNumber: 'Account Number',
            beneficiary: 'Beneficiary',
            wireAmount: 'Wire Amount',
            addAsNote: 'Add As Note',
            ccbRoutingNumber: '125108405',
            ccbDepositAccountNumber: '1500040298',
            ccbBeneficiary: 'Coastal Community Bank',
            wireProcessingTime: 'Once the wire is initiated by your bank, it can take 2 business days to receive and process',
        },
        PrivacyPolicy: {
            PrivacyPolicy: 'Privacy Policy',
        },
        TermsCondition: {
            TermsCondition: 'Terms & Condition',
        },
        CreditAgreement: {
            CreditAgreement: 'Credit Agreement',
        },
        card: {
            AssetCard: 'Asset Card',
            HomeTitle: 'Home Card',
            AutoTitle: 'Auto Card',
            YourCard: 'Your Card',
            MakeAPayment: 'Make A Payment',
            CashOut: 'Cash Out',
            AutoPay: 'AutoPay',
            AdvanceTransactionNotification: 'Schedule Large Purchase',
            BalanceTransfer: 'Balance Transfers',
            Rewards: 'Rewards',
            AccountDetails: 'Account Details',
            DepositCrypto: 'Deposit Crypto',
            AutoLoanRefinance: 'Auto Loan Refinance',
            ReportLostOrStolen: 'Report Lost Or Stolen',
            Pif: 'Share {pifAmount} To Friends',
            SupportAndHelp: 'Support & Help',
            LogOut: 'Log Out',
            Disclosure: 'Aven Financial, Inc. dba Aven NMLS 2042345. For licensing information, go to www.nmlsconsumeraccess.org',
        },
        yourCard: {
            ReportLostOrStolen: 'Report Lost or Stolen',
            CardActivation: 'Activate Card',
            YourCard: 'Your Card',
            CreditLimit: 'Credit Limit',
            APR: 'Current APR',
            AccountAgreement: 'Account Agreement',
            PolicyAndTerms: 'Policies and Agreements',
            PrivacyPolicy: 'Privacy Policy',
            TermsConditions: 'Terms & Conditions',
            Disclosures: 'Disclosures',
            CreditAgreement: 'Credit Agreement',
            AvailableCredit: 'Available Credit',
            Unlocked: 'Unlocked',
            cardTerms: 'Card Terms',
            refresh: 'Refresh',
            modal: {
                freezeCard: {
                    title: 'Card Locked',
                    description: `
                    <p>The following changes are in effect while your card is locked</p>
                    <dl>
                      <dt>Will Stop</dt>
                      <dd>- New Purchases</dd>
                      <dd>- New Cash Advances</dd>
                      <dd>- New Balance Transfers</dd>
                      <dd>- Secondary Cards</dd>
                      <dt>Will Continue</dt>
                      <dd>- Pending Transactions</dd>
                      <dd>- Subscriptions</dd>
                      <dd>- Recurring Payments</dd>
                      <dd>- Refunds and Credits</dd>
                    </dl>
                    `,
                },
                unrecoverable: {
                    title: 'Error',
                    description: `<p>Unable to complete request. Please contact Customer Service.</p>`,
                },
                recoverable: {
                    title: 'Error',
                    description: `<p>Unable to complete request. Please try again.</p>`,
                },
                cta: 'Got it',
            },
        },
        login: {
            phonePlaceholder: 'Mobile Number',
            codeHeadline: 'We sent a code to {contactInfo}',
            codePlaceholder: 'Confirmation Code',
            continue: 'Continue',
            notFound:
                'Please check the number again. If you do not have an existing account, please visit <a href="https://aven.com" class="text-underline" target="_blank" rel="noopener">aven.com</a>.',
            verificationNotCorrect: 'Verification code is incorrect',
            signInTitle: '<strong>Welcome!</strong><br/> Enter your mobile number.',
            signInOrActivate: 'Continue',
            biometrics: 'Unlock with Biometrics',
            Help: 'Help',
            LoginHelp: 'Login Help',
            helpDescription: 'If you did not get a text message, we can send it again.',
            resendCode: 'Resend Code by Text',
            sendToCall: 'Send Code by Call',
            sendToEmail: 'Send to Email',
            ChangeNumber: 'Change Number',
            notValidPhoneNumber: 'Not a valid phone number',
            appConsent: "By clicking Sign In, you consent to the terms of our <a href='https://aven.com/docs/DigitalServicesAgreement.pdf' target='_blank'> Digital Services Agreement</a>.",
            twilio: {
                cannotReceiveSms: `Sorry, it looks like your phone can't receive text messages. Please contact support to change your phone number.`,
            },
            loginHelpModal: {
                title: 'Need Login Help?',
            },
        },
        setPassword: {
            phonePlaceholder: 'Enter 6 digits',
            createPassword: `Great, let's set your password`,
            firstEntryPlaceholder: 'New Password',
            secondEntryPlaceholder: 'Confirm New Password',
            continue: 'Continue',
            confirm: 'Confirm',
            error: {
                personalInfoMismatchError: 'The personal information you have entered does not match our records. Please try again.',
                expiredTokenError: {
                    title: 'Session Expired',
                    description: 'Code reset session expired. Please try again',
                    cta: 'Go to Login',
                },
                maxIdentityVerificationAttemptsReachedError: {
                    title: 'Retry Limit Reached',
                    description: `Sorry, we could not verify your information. Please contact us at ${contact_info.dashFormatted} with any questions.`,
                    cta: 'Go to Login',
                },
            },
        },
        passwordLogin: {
            loginWithPassword: 'Enter your password',
            biometrics: 'Unlock with Biometrics',
            help: 'Help',
            forgotPassword: 'Forgot Password',
            resetPasswordDescription: 'Reset your password by verifying Identity',
            error: {
                accountNotFound: 'Account Not Found. Please contact Customer Service',
                incorrectPassword: 'The password you have entered is incorrect. Click “Forgot Password” if you need to reset your password.',
                accountLocked: 'For your security, your account has been locked for 24 hours. Click “Forgot Password” if you need to reset your password.',
            },
            placeHolder: 'Password',
        },
        unfreezeCard: {
            title: 'Unlock Card',
            message: 'Confirm your Password',
            freezeCardTitle: 'Freeze Card',
            cta: 'Continue',
            modal: {
                unfreezeCard: {
                    title: 'Card Unlocked',
                    description: `<p>Your card will now be fully functional and can be used for purchases, cash advances and balance transfers.</p>`,
                },
                unrecoverable: {
                    title: 'Error',
                    description: `<p>Unable to complete request. Please contact Customer Service.</p>`,
                },
                recoverable: {
                    title: 'Error',
                    description: `<p>Unable to complete request. Please try again.</p>`,
                },
                cta: 'Got it',
            },
            error: {
                incorrectPassword: 'Incorrect Password',
            },
        },
        SSNVerification: {
            Last4ofSSN: 'Last 4 of SSN',
            DateOfBirth: 'Date of Birth',
            setupPassword: 'To set up a password, please confirm your identity.',
            resetPassword: 'To reset your password, please confirm your identity',
            dob: 'MM/DD/YYYY',
            cta: 'Continue',
        },
        CardActivation: {
            headerTitle: 'Activate Card',
            CardActivationCancel: 'Not Now',
            descTitle: 'Enter the last 4 digits of your new card to activate',
            Last4ofCardNumber: 'Last 4 of Card Number',
            ExpirationDate: 'Expiration Date (MMYY)',
            continue: 'Continue',
            cashOutUpfrontTitle: 'Activate Your Card',
            cashOutUpfrontSubtitle: "Let's wrap things up by sending {cashOutUpfrontAmount} to your bank account",
            cashOutUpfrontButton: 'Cash Out Now',
            cashOutUpfrontBtInstead: 'Balance Transfer Instead',
            cashOutUpfrontInProgressTitle: 'Pending Card Activation',
            cashOutUpfrontInProgressSubtitle: 'Your Cash Out is in process. Your card will be activated once completed',
            cashOutUpfrontInProgressButton: 'Got it',
            ERROR_CARD_ACTIVATION_DATA_INVALID: {
                title: 'Card Number Not Found',
                body: ' Please verify the card number on the back of your Aven card and retry.',
            },
            ERROR_CARD_ACTIVATION_OTHER_OWNER: {
                title: `Please Activate {firstName}'s Card`,
                body: `You are logged in as {firstName}. Please enter the last 4 of {firstName}'s card or log in with {cardholderName}'s phone number.`,
            },
            ERROR_CARD_ACTIVATION_CLOSED_CARD: {
                title: 'Please Use Your New Card',
                body: 'You are using your old Aven card number. Please enter the last 4 digits of your new card.',
            },
            ERROR_IN_RESCISSION_PERIOD: {
                title: 'You can activate your card soon. You are currently within your 3-day cancellation period. Please activate after {date}.',
            },
            ERROR_PRIMARY_CARD_NOT_ACTIVATED: {
                title: 'Please have the primary cardholder activate their card first.',
            },
            ERROR_BLOCKED_BY_TITLE_INSURANCE: {
                title: 'You can activate your card soon. We are waiting to receive the Final Title Policy from our title agent. This typically takes a week after your account was opened.',
            },
            ERROR_BLOCKED_BY_HAZARD_INSURANCE: {
                title: "You can activate your card soon. We are waiting to receive final verification from your homeowner's insurance. This typically takes a week after your account was opened.",
            },
            ERROR_BLOCKED_BY_EMPLOYMENT_VERIFICATION: {
                title: 'You can activate your card soon. We are finalizing employment verification. This typically takes a week after your account was opened.',
            },
            ERROR_BLOCKED_BY_MLS_LISTING: {
                title: 'You can activate your card soon. We are waiting to receive final clearance on your property profile. This typically takes a week after your account was opened.',
            },
            ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE: {
                pageTitle: 'Refinance In Progress',
                title: 'You can activate your card soon. We are waiting for your previous HELOC lender to release their lien. This typically takes 2-3 week after your account was opened.',
                body: `
                • We request a payoff quote the same day we receive your most recent HELOC statement from the other lender.
                <br>
                <br>
                • We send payoff funds within 1 business day of receiving the payoff quote! 
                <br>
                <br>
                • Once we send the payoff funds, the other lender typically takes 7-10 business days to payoff and close your existing HELOC. 
                <br>
                <br>
                • Once the loan is closed, your existing HELOC lender will release the lien. This process can take up to 21 business days depending on the lender. 
                <br>
                <br>
                • You can activate your card after the other lender has released the lien. 
                <br>
                <br>
                • We will keep you updated throughout the refinance process as we receive updates from your other lender.
                `,
            },
            ERROR_BLOCKED_BY_PENDING_CONTINGENCIES: {
                title: 'You can activate your card soon. We are doing the final account verifications. This typically takes a week after your account was opened.',
            },
            ERROR_GENERIC_FAILED_CARD_ACTIVATION: 'We were unable to activate your card to due an error in our system. We are investigating, and will be in touch.',
            ERROR_BLOCKED_BY_UCC_INCOMPLETE_FLOW: 'Thank you for completing your application. All we need you to do is to verify your stated income before you can activate your card.',
            activationSuccessful: {
                imgAlt: 'Activation Successful',
                descTitle: 'Congrats! Your card is now active and ready to use',
                letsGo: "Let's Go",
            },
            primeV3Activation: {
                descTitle: 'Want a lower APR? Cash Out {primeV3DrawDollarAmount} to reduce your APR from {currentAprPercent} to {primeV3PromoAprPercent}',
                imgAlt: 'Cash Out In Activation',
                continue: 'Continue',
                skip: 'Skip',
            },
            unrecoverableActivationError: {
                description: 'Oh no, we were unable to activate your card. Please contact support.',
                descriptionNonMobile: `Oh no, we were unable to activate your card. Please contact support at ${contact_info.dashFormatted}`,
                buttonText: 'Call Support',
            },
        },
        cardActivationContingency: {
            learnMore: 'Learn More',
            gotIt: 'Got It',
        },
        MailerActivation: {
            cashOutUpfrontSubtitle: "Let's transfer {cashOutUpfrontAmount} to your bank account",
            cashOutUpfrontButton: 'Continue',
            mailerInviteSubtitle: 'Your {formattedCashOutAmout} Cash Out is set! Enter the 6 digit activation code you should have received in the mail.',
            cardActivationSubtitle: 'Your {formattedCashOutAmout} Cash Out is set! Enter the last 4 digits of your Aven credit card.',
            mailerInviteCodePlaceholder: '6 Digit Code',
            mailerInvitePrimaryButton: 'Continue',
            mailerInviteSecondaryButton: "Don't have the code?",
            mailerCodeValidationError: 'Invalid code, please try again',
            last4CardActivationError: 'Invalid number, please try again',
            doNotHaveMailerCodeButton: "Don't have the code?",
            noMailerCodeHeader: "Don't Have The Code?",
            noMailerCodeSubHeader: 'Never received the letter?',
            noMailerCodeText:
                'If you never received the letter or it got lost, you can request to get a new one. It typically takes 3 days to arrive. If you have already received your credit card in the mail, you can also use the last 4 digits of the card instead.',
            sendANewLetterButton: 'Send New Letter',
            useLast4Button: 'Use Last 4 Of Credit Card',
            mailerActivationPromptTitle: 'Have you received a letter with a 6 digit activation code?',
            mailerActivationPromptYesButton: 'Yes, I Have A Code',
            mailerActivationPromptNoButton: 'No, Not Yet',
            mailerActivationInputTitle: 'Enter the 6 digit activation code from the letter',
            mailerActivationSuccessTitle: 'Congratulations! Your account is now activated to Cash Out and Balance Transfer. Be sure to also activate your card when it arrives.',
        },
        VideoAndLocationActivation: {
            ios: {
                askForAllowWhileInUse: 'Please accept location permissions to continue.',
                askForPrecise: 'It seems like Precise Location is not enabled. Please enable in location settings to continue.',
                askForContacts: 'Please accept contact permissions to continue.',
                askForCamera: 'Please accept camera permissions to continue.',
                askForMicrophone: 'Please accept microphone permissions to continue.',
                ctaAskForWhileInUse: 'Secure With Location',
                ctaAskForAllowAlways: 'Update Location Settings',
                ctaAskForPrecise: 'Open Location Settings',
                ctaAskForMicrophone: 'Provide Microphone',
                ctaAskForCamera: 'Provide Camera',
            },
            android: {
                askForAllowWhileInUse: 'Please accept location permissions to continue.',
                askForPrecise: 'It seems like Precise Location is not enabled. Please enable in location settings to continue.',
                askForContacts: 'Please accept contact permissions to continue.',
                askForCamera: 'Please accept camera permissions to continue.',
                askForStorage: 'Please accept storage permissions to continue.',
                ctaAskForWhileInUse: 'Secure With Location',
                ctaAskForAllowAlways: 'Open Location Settings',
                ctaAskForPrecise: 'Open Location Settings',
                ctaAskForStorage: 'Allow Video Storage',
                ctaAskForCamera: 'Provide Camera',
            },
            videoInstructions: {
                howItWorks: 'HOW IT WORKS',
                step1: 'Allow required permission to confirm location and identity.',
                step2: 'Take a quick video starting in your front yard, then take us through your kitchen and living room. Make sure everything from floor to ceiling is visible.',
                propertyConfirmation: 'I confirm that I will submit video of the property located at {formattedAddress}',
            },
            ctaTakeVideo: 'Take Video',
            pleaseTakeVideo: 'Please take a short video to continue.',
            overview: `Let’s just do a quick home verification. Please take a short video walkthrough of your home.`,
            ctaAcceptPermissions: 'Take A Video',
            askToDownloadApp: 'You will need to download the Aven App for your mobile device to activate your Aven Card.',
            askToUpdateApp: 'You will need to update the Aven App for your mobile device to activate your Aven Card.',
            ctaAskToDownloadApp: 'Go To App Download',
            ctaDenyPermissions: 'No Thanks',
            pendingApproval: `All set!<br/><br/>Our team is reviewing—takes a couple of days. Once approved, you can activate. If we need additional information, we will reach out.`,
            ctaPendingApproval: 'Continue',
            uploadingVideoLoadingMessage: 'Processing. Please keep the app open while we a finalize.',
        },
        cashOutUpfrontBalanceTransferInstead: {
            title: 'Balance Transfer',
            body: 'With Aven, you can transfer funds directly to your bank account. Once the money is deposited, you can use to pay off your credit cards or settle other debts.',
            footnote: 'Note: The one-time fee is the same for both Cash Outs and Balance Transfers, so there is no difference in cost.',
            cta: 'Cash Out Now',
        },
        FixedTermCashOutUpfront: {
            startFixedTermCashOutTitle: `Let's transfer {amount} to your bank account.`,
            inProgressTitle: `Congrats! Your {amount} transfer is in process.`,
        },
        CheckCardArrival: {
            description: 'Do you have your new Aven Visa&reg; Credit Card?',
            shippingInfo: 'Your card was shipped on {shippedOn} and should arrive by {arriveBy}',
            cta: 'Yes, I Have It',
            no_cta: 'No, Not Yet',
        },
        SecureWithLocation: {
            description: 'Increase security against fraud and theft by enabling device location',
            cta: 'Secure With Location',
            no_cta: 'Not Now',
        },
        FastFunding: {
            ctaContinue: 'Continue',
            ctaCashOutNow: 'Cash Out Now',
            ctaDeny: 'No Thanks',
            ctaLater: 'Later',
            mainText: {
                fastFundingExplanationPriorScreen: `No problem, do you want to check if we can get you early access to Cash Out to your bank?`,
                fastFundingExplanation: `Do you want to check if we can get you early access to Cash Out to your bank?`,
                enterBank: 'Enter your bank account details to check if we can send cash to it.',
                validated: `Congrats! We were able to validate your account. You can Cash Out to your bank account now.`,
                notValidated: `Unfortunately we were unable to validate your account. As soon as the letter or card arrives, you can activate your account.`,
            },
        },
        GetHelpFeedback: {
            title: 'Get Help',
            feedbackPrompt: 'We would love your feedback on how we can help to get you back on track.',
            enterFeedback: 'Enter Feedback...',
            thankYouForYourSubmission: 'Thank you for your submission',
            ourTeamWillBeReviewingYourFeedback: 'Our team will be reviewing your feedback and reach out to you shortly',
            ctaSubmit: 'Submit',
            ctaDone: 'Done',
        },
        DqInterstitial: {
            mainText: 'Your account is past due. Please pay the past due amount of {amount} today.',
            mainTextWithLateFeeReversalOffer: 'Your account is past due. Pay the past due amount of {amount} today and we will reverse late fees.',
            ctaMakeAPayment: 'Make A Payment',
            ctaNotNow: 'Not Now',
            ctaGetHelp: 'Get Help',
        },
        CreatorChallenge: {
            title: 'Record A Video',
            recordAVideoTitle: 'Record a video, get $100 and a chance at $1000.',
            recordAVideoBody:
                'Record a video about why you love Aven, and get $100 in statement credit if we use your video in an upcoming testimonial. The best video every week will win $1000 in statement credit.',
            nextSteps: 'Next Steps',
            nextStep1: 'Record a 15-30 second video on your phone',
            nextStep2: 'Click “Upload” and select your recorded video',
            nextStep3: 'We will send you statement credit to your Aven card if you win!',
            uploadAVideo: 'Upload A Video',
            uploadedSuccessfully: 'Video uploaded successfully!',
            disclosure: `By uploading a video you agree to the <a style='text-decoration: underline' href='https://aven.com/docs/AvenTestimonialTerms.pdf' target='_blank'>Terms and Conditions</a>`,
        },
        AddBankAccount: {
            AddBanksAccount: 'Add Bank Account',
            AccountHolderName: 'Account Name',
            AccountHolderNumber: 'Account Number',
            ConfirmAccountHolderNumber: 'Confirm Account Number',
            ValidationErrorMessage: 'Account numbers must match',
            Nickname: 'Account Nickname (ex. Chase Checking)',
            PhoneNumber: 'Phone Number',
            AccountType: 'Account Type',
            RoutingNumber: 'Routing Number',
            State: 'State',
            Zipcode: 'Zipcode',
            AddBankAccount: 'Add Bank Account',
            PersonalChecking: 'Personal Checking',
            PersonalSavings: 'Personal Savings',
            BusinessChecking: 'Business checking',
            BusinessSavings: 'Business Savings',
            YourDetails: 'Your Details',
            AccountDetails: 'Account Details',
        },
        engagementLinkBankAccount: {
            title: 'Link Bank Account',
            linkAccount: 'Link Bank Account',
        },
        engagementLinkBankAccountSuccess: {
            title: 'Link Bank Account',
            subTitle: `Success!  Your  <strong>{accountName}</strong> is linked`,
            inRescissionProcessing: 'Your deposit and updated monthly payment will be processed on {processingDate}, after your 3-day rescission period ends.',
            processing: 'Your deposit and updated monthly payment will be processed on {processingDate}',
            doneCta: 'Continue',
        },
        MortgagePaymentRewards: {
            cardTitle: 'Earn 1% Cash Back On Your Mortgage',
            cardSubtitle: 'Turn your mortgage payments into 1% cash back. Just keep paying like you do.',
            headerTitle: `Mortgage Cash Back`,
            promptTitle: `Earn 1% cash back on your mortgage payments`,
            howItWorks: `How it works`,
            howItWorksItem1: `Confirm your monthly mortgage payment amount`,
            howItWorksItem2: `Spend $4,000 in the first three months with your Aven Card`,
            howItWorksItem3: `Automatically earn 1% cash back on your mortgage payments<sup>1</sup>`,
            promptContinue: `Continue`,
            footer: `<sup>1</sup>Limited to $200 annually. <a style='text-decoration: underline' href='https://aven.com/public/docs/MortgagePaymentCashback10Terms' target='_blank'>Terms and Conditions</a> apply`,
            disclosure: `We don't store your credentials or have direct access to your account.`,
            plaidLinkDescription: `To confirm monthly mortgage payments, please link the bank account where your mortgage payments are made from.`,
            paymentSelectionDescription: `Select your monthly mortgage payment from your transactions at {bankName} account ending in {accountMask}`,
            plaidLinkButton: `Link Account`,
            noMortgagePaymentsError: `Sorry, we didn't match any possible mortgage payments from your linked account. Please try another bank account.`,
            setupConfirmTitle: `All set! Let's start earning cash back on your mortgage payment.`,
            setupConfirmSubTitle: `Remember you'll need to spend $4,000 in your first 3 months to be eligible. You can track your progress on the main page.`,
            setupConfirmButton: `Confirm`,
            setupNearCompletionTitle: `Thanks for confirming your monthly mortgage payment, we're finalizing some details.`,
            rewardsPrerequisiteTitle: `Spend $4,000 within three months`,
            rewardsTitle: `Rewards Overview`,
            genericError: 'Sorry, something went wrong. Please try again.',
        },
        MortgageCashbackV2: {
            cardTitle: 'Mortgage Rewards',
            pageTitle: 'Mortgage Rewards',
            tagText: 'Special Offer',
            contentText: 'If you have a mortgage, we reward you. Pay your mortgage like normal. 1 point for every dollar.',
            pointsEarnedText: `You have earned <strong>{points} points</strong> so far!`,
            pointsEarnedButtonText: 'Share With Friends',
            buttonText: 'Claim Rewards',
            landingPageTitle: `If you have a mortgage, we reward you. 1 point for every dollar.  Redeem towards travel.`,
            howItWorksItem1: 'Connect your bank account to confirm your monthly mortgage payment amount',
            howItWorksItem2: 'Keep paying your mortgage like you normally do',
            howItWorksItem3: 'Redeem points every month. Book your next stay.',
            confirmTitle: `All set! You just earned {availablePoints} points!`,
            spotsLeft: 'Spots Left',
            shareTitle: 'Share With Friends',
            shareContent: 'Tell your friends that they can earn rewards on their mortgage. {availableSpots} spots left for this special offer.',
            shareButton: 'Share With Friends',
            shareButtonDismiss: `Don't Tell Friends`,
            smsMessage: `Check this out! Only {availableSpots} spots left. Aven offers rewards for having a mortgage. https://aven.com/mortgagecashbackv2`,
            footer: `By continuing, you agree to the <a style='text-decoration: underline' href='https://aven.com/public/docs/MortgagePaymentCashbackV2Terms' target='_blank'>Terms and Conditions</a> of the Mortgage Rewards Program.`,
            footer1: `1. Up to 20,000 points annually.`,
        },
        appHeader: {
            HELP: 'HELP',
        },
        dashboard: {
            Activity: 'Activity',
            Rewards: 'Rewards',
            Statements: 'Statements',
            Card: 'Account',
        },
        ReportLostOrStolen: {
            ReportLostOrStolen: 'Report Lost or Stolen',
            Step1Title: '1) Temporarily De-Activate Card',
            Step1Subtitle: 'If you think you card is lost or stolen, we recommend that you temporarily pause your card first to prevent any unwanted charges.',
            CardActive: 'Card Active',
            Step2Title: '{stepNumber}) Replace Card',
            Step2Subtitle: 'If you can’t find your card or you see unwanted charges, you can cancel your current card and we will send a new one to:',
            cancelAndReplace: 'Replace Card',
            CardLocked: 'Card Locked',
            ModalTitle: 'The following changes are in effect while your card is locked.',
            CardStop: 'Will Stop:',
            CardStopItem1: '- New Purchases',
            CardStopItem2: '- New Cash Advances ',
            CardStopItem3: '- New Balance Transfers',
            CardStopItem4: '- Secondary Cards',
            CardContinue: 'Will Continue:',
            CardContinueItem1: '- Pending Transactions',
            CardContinueItem2: '- Subscriptions',
            CardContinueItem3: '- Recurring Payments',
            CardContinueItem4: '- Refunds and Credits',
            GotIt: 'Got It',
            CardStatusError: 'There was an error getting your card status. Please refresh and try again.',
            FreezeCardError: 'There was an error trying to freeze your card. Please refresh and try again.',
            CallUs: 'Call Us',
        },
        ReplaceCard: {
            limitReached: {
                title: 'Replace Card',
                description: 'Please contact support for a replacement Aven Card.',
                cta: 'Text Support',
            },
            lost: {
                title: 'Lost / Stolen',
                description: `Your current card will freeze and no longer work.<br><br>Your card replacement will be sent via USPS. It typically takes 5 - 10 business days to receive your new Aven card.`,
            },
            damaged: {
                title: 'Damaged',
                description: `<b>You may continue using your card if:</b><br>- Date and CVC are legible<br>– Chip works<br>– Magnetic stripe is functional<br>– It’s not temporarily de-activated<br><br>Your card replacement will be sent via USPS. It typically takes 5 - 10 business days to receive your new Aven card.`,
            },
            successModal: {
                title: 'Hoorah!',
                description: 'We received your request and your card is on the way.',
            },
            errorModal: {
                title: 'Request Error',
                description: 'Sorry, we could not complete your request. Please try again. If you continue to have trouble, please contact support.',
                textSupport: 'Text Support',
            },
            cta: 'Confirm Replacement',
            noThanks: 'No Thanks',
        },
        accountDetails: {
            AccountDetails: 'Account Details',
            AccountNumber: 'Account Number',
            PersonalDetails: 'Personal Details',
            CreditScore: 'Credit Score',
            PaymentSources: 'Payment Sources',
            NotificationSettings: 'Notification Settings',
            AvenPrimeSettings: 'Aven Prime',
            AccountTerms: 'Account Terms',
            CreditLimit: 'Credit Limit',
            AvailableCredit: 'Available Credit',
            CurrentAPR: 'Current APR',
            IntroPeroidEndDate: 'Intro Rate Ends {date}',
            AprAfterIntroPeroid: 'APR After Intro Period',
            primeV3APRDrawSubTitle: `<b>Get {primeV3PromoAprPercent}</b> if you draw {maxAmount} or more by {primeV3Date}. If not, it will be <b>{aprPercent}</b>.`,
            primeV3APRCompleteSubTitle: `You have unlocked the APR discount! Your APR will be <b>{primeV3PromoAprPercent} by {primeV3Date}</b>.`,
            BalanceTransferFee: 'Balance Transfer Fee',
            BalanceTransferFeeWithHausMember: 'Balance Transfer Fee (HAUS Member)',
            CashOutFee: 'Cash Out Fee',
            UnlimitedCashback: 'Unlimited Cashback',
            MonthlyFee: 'Monthly fee',
            PolicyAndAgreement: 'Policy And Agreement',
            AccountAgreement: 'Account Agreement',
            PrivacyPolicy: 'Privacy Policy',
            TermsAndConditions: 'Terms & Conditions',
            RewardTermsAndConditions: 'Reward Terms & Conditions',
            Disclosures: 'Disclosures',
            rescissionPeriodEnd: 'Rescission Period Until',
            ManageAccount: 'Manage Account',
            GetPayoffQuote: 'Get Payoff Quote',
            CloseAccount: 'Close Account',
            ReinstateCreditLimit: 'Reinstate Credit Limit',
            AuthorizedCardholders: 'Authorized Cardholders',
        },
        authorizedCardholders: {
            AuthorizedCardholders: 'Authorized Cardholders',
            ConfirmDisableText: "Are you sure you want to disable {name}'s Account? Their card will no longer work, and they will no longer have access to this account",
            ConfirmDisableCta: 'Disable Card',
            ConfirmEnableText: "Are you sure you want to enable {name}'s account? Their card will now continue to work, and will have access to this account.",
            ConfirmEnableCta: 'Enable Card',
            KeepEnabledCta: 'Keep Card Enabled',
            KeepDisabledCta: 'Keep Card Disabled',
            UpdateError: 'There was an error updating your authorized cardholders. Please try again later.',
        },
        personalInformation: {
            PersonalInformation: 'Personal Details',
            Name: 'Name',
            Contact: 'Contact Information',
            MobileNumber: 'Mobile Number',
            Email: 'Email',
            Address: 'Address',
            UpdateAddressInfo: 'For security reasons, please call us at ',
            UpdateAddressInfo2: 'to change any of your personal information.',
        },
        PaymentSources: {
            PaymentSources: 'Payment Sources',
            PaymentSource: 'Payment Source',
            AddNewSource: 'Add Payment Source',
            ChaseChecking: 'Chase Checking',
            Amount: 'Amount',
            PayFrom: 'Pay From',
            PaymentSourceWarning: 'Please set the default payment source to AutoPay',
            Ok: 'OK',
            Type: 'Type',
            Account: 'Account',
            Nickname: 'Nickname',
            AddAccount: 'Add Account',
            RemoveSource: 'Remove Source',
            ConfirmRemoveText: 'Are you sure you want to remove this payment source? This could affect future payments or autopay.',
            ConfirmRemoveCta: 'Yes, Remove Source',
            AddDebitCard: 'Add Debit Card',
            InvalidDebitCardInput: 'The inputted information is invalid. Please double check and try again.',
            AddNewBankAccount: 'Add New Bank Account',
            SaveBankAccount: 'Save Bank Account',
            retryDebitSubheading: 'We were unable to process your payment from this bank account. <span class="fw-bold">Let\'s add your debit card instead.</span>',
            retryDebitSubheadingNsfReturns: 'This checking account had more than {numNsfReturns} non-sufficient fund returns. <span class="fw-bold">Let\'s add your debit card instead.</span>',
            AutoPayFormValidation: `Something went wrong. We were unable to turn off AutoPay. Please contact support at ${contact_info.dashFormatted}.`,
            secondaryCardHolderAutoPayOnMessage: 'To edit AutoPay, please have the primary cardholder login',
            secondaryCardHolderAutoPayOffMessage: 'To turn on AutoPay, please have the primary cardholder login to their account and turn on AutoPay',
            GetPaymentSourcesError: 'There was an issue trying to retrieve your payment sources. Please refresh and try again.',
            AddPaymentSourceError: 'There was an issue trying to add the payment method. Please try again.',
        },
        PaymentSource: {
            PaymentSource: 'Payment Source',
            BillingAccountNotFound: 'No Payment Sources Yet',
            AddBank: 'Please add a payment source to make payments from.',
            ChaseChecking: 'Chase Checking',
            Cardending: 'Card ending ',
            AddPaymentMethod: 'Add Payment Source',
            AddBankAccount: 'Add Bank Account',
            AddDebitCard: 'Add Debit Card',
            DeleteFundingSource: 'Delete Funding Source?',
            SelectPaymentSource: 'Select Payment Source',
            Delete: 'Delete',
        },
        // Footer defined here
        AutoPayAmount: {
            legalText1: 'We will initiate the payment on your due date (so you won’t be late for your payment), but it might take a few days to have the funds withdrawn from your account.',
            legalText2Html:
                "By clicking Turn On AutoPay you agree to the terms and conditions of the <a style='text-decoration: underline' href='https://aven.com/docs/AutoPayAgreement.pdf' target='_blank'>Aven Automatic Payment Service Agreement</a>, which governs the use of automatic payments.",
        },
        AutoPayTooltip: {
            title: '{cashback} Unlimited Cashback',
            content: 'Set up recurring payments (AutoPay) and increase your cashback to {cashback}',
            pill: '{cashback} Cashback',
        },
        PushNotificationPermissions: {
            subtitle: 'Get notified about new purchases,\npayments, and statements',
            originationSubtitle: 'Get notified about any \nupdates or changes in the\n status of your application',
            ctaPrimary: 'Allow Notifications',
            ctaSecondary: 'Not Now',
        },
        NotificationSettings: {
            NotificationSettings: 'Notification Settings',
            PushNotification: 'Send Push Notifications To This Device',
        },
        AvenPrimeSettings: {
            title: 'Aven Prime',
            toggle: 'Enable',
        },
        balanceTransfer: {
            title: 'Balance Transfer',
            balanceTransferConfirmPaybackHeader: 'Payback',
            submitBalanceTransferForm: 'Continue',
            fixedInstallmentVsRevolvingPlanTitle: 'Fixed Installment vs. Revolving Plan?',
            fixedFinanceChargeVsRevolvingPlanText: `
                <p class="mb-3">You have two options for monthly payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you balance transfer + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Set up fixed monthly payments – no interest, just a fixed monthly fee. You can lock in your payments for 5 or 10 years. Your fixed rate is {fixedInstallmentApr}%. Once started, your payments and rate will NOT change for the entire length of the plan.</p>
            `,
            SimpleInterestLoanVsRevolvingPlanText: `
            <p class="mb-3">You have two options for monthly payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you balance transfer + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Lock in a fixed monthly payment for 5 or 10 years. Your fixed rate is based on your current Aven APR + a {fixedInstallmentAprPremium} premium to lock in the rate. Once started, your payments and rate will NOT change for the entire length of the plan.</p>
            `,
            SimpleInterestLongTermLoanVsRevolvingPlanText: `
            <p class="mb-3">You have two options for monthly payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you balance transfer + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Lock in a fixed monthly payment. Your fixed rate is based on your current Aven APR + a premium to lock in the rate. Once started, your payments and rate will NOT change for the entire length of the plan.</p>
            `,
            fixedInstallmentNotAvailableTitle: 'Why Is Fixed Not Available?',
            fixedInstallmentNotAvailableDueToHCMText:
                'Your APR for our fixed monthly payment (Aven Simple Loan) exceeds the High-Cost Mortgage limit set by regulation (CFPB § 1026.32). If the Fed lowers interest rates, this feature may be available in the future.',
            fixedInstallmentNotAvailableDueToTeaserText: 'Fixed monthly payment (Aven Simple Loan) are not available during the {balanceTransferPromoAprPercentText} intro APR period.',
            fixedInstallmentNotAvailableDueToPendingPrimeV2OptOut: 'Fixed monthly payment (Aven Simple Loan) are not available until your prime opt-out request is completed.',
            startBalanceTransfer: 'Start Free Balance Transfer',
            balanceTransferSuccessTitle: 'Balance Transfer Success',
            balanceTransferFailureTitle: 'Balance Transfer Failed',
            balanceTransferSuccessContent: 'Your balance transfer has been successfully scheduled. Confirmation Number: {confirmationNumber}.',
            balanceTransferFailureContent: 'Your balance transfer request has failed. Please contact customer service for assistance.',
            balanceTransferSuccessButton: 'Done',
            balanceTransferFailureButton: 'Retry',
        },
        balanceTransferSweep: {
            error: {
                genericError: 'Sorry, something went wrong. Our team has been notified and will resolve the issue as soon as possible.',
            },
            promoBannerTitle: 'Balance Transfer Saver Program',
            promoBannerDescription: 'Save up to 50% on your monthly payment when you transfer your {formattedTotalDebt} of high interest credit card balances.',
            paymentCalculationHeader: 'Payment Calculation',
            transferAmountLabel: 'Transfer Amount',
            transferFeeLabel: 'Transfer Fee ({feeRate})',
            addedToBalanceLabel: 'Added To Balance',
            planLabel: 'Plan',
            planFixedMonthlyPayments: 'Fixed Monthly Payments',
            termLabel: 'Term',
            termMonths: '{months} Mo.',
            rateLabel: 'Rate',
            fixedMonthlyPaymentLabel: 'Fixed Monthly Payment',
            confirmTransferCta: 'Lower My Monthly Payment',
            initiateTransferCta: 'Initiate Transfer',
        },
        autoBalanceTransfer: {
            errorMessage: 'Sorry, something went wrong. Our team has been notified and will resolve the issue as soon as possible.',
            chooseLinkedCards: {
                title: 'Recurring Auto Transfers',
                subTitle: 'SELECT CARDS',
                cta: 'Confirm',
            },
            selectAmount: {
                title: 'Transfer Amount',
                subtitle: 'Enter the amount you want to auto transfer from <b>{accountName}</b>',
                placeholder: 'Transfer Amount',
                cta: 'Set Transfer Amount',
                noTransferFee: {
                    title: 'Congrats! No Transfer Fees',
                    message: 'You will not be charged a transfer fee because you have Auto Transfer turned on for this card.',
                },
            },
            balanceTransferRequested: {
                title: 'Auto Transfer Requested',
                message: '{amount} has been requested to transfer from your card {accountName}. This usually takes up to 7 business days to reflect on your other card.',
                cta: 'Done',
            },
            information: {
                header: 'Recurring Auto Transfers',
                title: 'Save money with recurring auto balance transfers – $0 transfer fee.',
                message: "Once a month we'll ask how much you would like to balance transfer from your other credit cards & we'll do it hassle free - for $0.",
                ctaPrimary: 'Turn On',
                ctaSecondary: 'Not Now',
            },
        },
        cashOut: {
            title: 'Cash Out',
            ineligibleSecondaryCard: 'Unfortunately, Cash Out can only be initiated by the primary cardholder. Please have the primary cardholder perform a Cash Out.',
            emptyTitle: 'Cash Out To Your Bank',
            emptySubTitle: 'Get the same low rate as regular purchases. Just a simple {cashOutFee} transfer fee. It usually takes around 2 business days to reflect in your bank account.',
            cta: 'New Cash Out',
            cashOutFullDrawSectionHeader: 'Amount',
            cashOutFullDrawPrimeV3MaxAmountBadge: 'Lower APR',
            cashOutFullDrawPrimeV3MaxAmountSubtitle: 'Draw {primeV3DrawDollarAmount} by {primeV3Date} to get the lower APR of {primeV3PromoAprPercent}.',
            cashOutFullDrawPrimeV3MaxAmountLowerFeeBadge: 'Lower Fee',
            cashOutFullDrawPrimeV3MaxAmountLowerFeeSubtitle: 'Draw {primeV3DrawDollarAmount} by {primeV3Date} to get the lower cashout fee of {primeV3PromoCoFeePercent}.',
            cashOutFullDrawPrimeV3CustomAmountTitle: 'Custom Amount',
            cashOutFullDrawPrimeV3CustomAmountSubtitle: 'More than {maxAmount} will qualify for {primeV3PromoAprPercent} APR, otherwise {aprPercent} APR.',
            cashOutFullDrawPrimeV3CustomAmountLowerFeeSubtitle: 'More than {maxAmount} will qualify for {primeV3PromoCoFeePercent} fee, otherwise {coFeePercent} fee.',
            linkBankCta: 'Select Your Bank Account',
            cashOutDailyLimitInfo: `For security reasons, there is a ${generic.prettyPrintDollarAmount(
                String(MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS)
            )} daily maximum for combined Cash Outs and Balance Transfers.`,
            cashOutLimitInfo: 'The maximum amount is based on your available credit minus the {cashOutFee} Cash Out fee.',
            cashOutLimitInfoNoFee: 'The maximum amount is based on your available credit.',
            maxCashOutAfterLargePayment: `For your security, Cash Outs are restricted to ${generic.prettyPrintDollarAmount(
                String(MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT)
            )} for ${MIN_DAYS_BETWEEN_PAYMENT_AND_CASH_OUT} days after making a payment of greater than ${generic.prettyPrintDollarAmount(String(PAYMENT_AMOUNT_BEFORE_BLOCKING_CASH_OUT))}.`,
            fullDrawInfo: `For security reasons, there is a ${toFormattedUSDStripTrailingZeroCents(
                MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS
            )} daily deposit maximum. Amounts over ${toFormattedUSDStripTrailingZeroCents(
                MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS
            )} will automatically be broken into multiple deposits over a few days.`,
            plaidGenericError: 'We require additional information to complete the transaction',
            plaidNameMismatchError:
                'We are unable to match the name on the bank account to your name in our systems. We apologize for the inconvenience. Please try another account or manually upload your account information.',
            manualBankCannotVerifyError: 'This account could not be verified, please try with a different account.',
            manualBankBadRoutingNumberError: 'The routing number is invalid, please double check and try again.',
            uploadBankStatements: 'Upload Bank Statement Instead',
            uploadBankStatementFormSubTitle:
                'To confirm ownership of that account, please add a voided check or the first page of your latest bank statement showing your full name and complete account number.',
            uploadBankStatementOrVoidedCheckButton: 'Statement or Voided Check',
            bankAccountInformationFormSubtitle: 'To which bank account do you want to Cash Out?',
            submitBankStatementsCta: 'Continue',
            uploadBankStatementSourceError: 'There was an issue trying to upload bank statements. Please try again.',
            cashOutFormSubText: 'For security reasons, you will be asked to login to your online bank account. The maximum amount is based on your available credit minus the {cashOutFee} fee.',
            cashOutFormSubTextNoFee: 'For security reasons, you will be asked to login to your online bank account. The maximum amount is based on your available credit.',
            verifyPassword: 'Confirm your Password',
            ssnVerify: 'Confirm the last 4 digits of your social security',
            ssnPlaceholder: 'Last 4 Social Security Number',
            ssnInvalid: 'Please check the last 4 digits of your social security number',
            addBankAccountPlaid: 'Link Account – Secure & Instant',
            addBankAccountUploadStatements: 'Manually Upload Information',
            selectCashOutAccountHeader: 'Send Cash to...',
            addNewBankAccountOptionLabel: 'Add New Bank Account...',
            chooseOrAddAccountLabel: 'Choose or Add Account',
            submitCashOutForm: 'Continue',
            inputPlaceholder: '$0.00',
            inputLabel: 'Amount ({availableCredit} Maximum)',
            cashOutText: 'Amount + {cashOutFee} Fee',
            cashOutConfirmAmount: 'Total Transferred to Bank',
            cashOutConfirmAmountWithChunkExplanation: 'Total Transferred to Bank<sup><a href="#cashOutChunkExplanationFootnote">1</a></sup>',
            cashOutConfirmationFee: '{fee} Fee',
            cashOutOriginationConfirmationFee: '{fee} Origination Fee (one-time)',
            cashOutFirstDrawConfirmationFee: '{fee} Fee (one-time)',
            cashOutConfirmationTotal: 'Added To Balance',
            cashOutConfirmationFixedLoanTotal: 'Fixed Plan Balance',
            cashOutConfirmAmountHeader: 'Amount',
            cashOutConfirmPaybackHeader: 'Payback',
            cashOutConfirmPaybackMethod: 'Method',
            cashOutConfirmPaybackAddBalance: 'Standard',
            cashOutConfirmPaybackFixedLoan: 'Fixed Monthly Payments',
            cashOutConfirmPaybackApr: 'Current APR',
            cashOutConfirmPaybackPromoApr: 'Intro APR',
            cashOutConfirmPaybackTerm: 'Term',
            cashOutConfirmPaybackRate: 'Rate',
            cashOutConfirmPaybackMonthlyPayment: 'Monthly Payment',
            cashOutConfirmAutoPayHeader: 'AutoPay',
            cashOutConfirmAutoPayTitle: 'Turn On Minimum AutoPay',
            cashOutConfirmAutoPayBody: 'For peace of mind, turn on AutoPay for the minimum amount from your bank account ending in {accountEnding}',
            cashOutViaPlaidSuccessTitle: 'Cash Out Requested',
            cashOutViaBankStmtUploadTitle: 'Reviewing Cash Out',
            cashOutViaPlaidSuccessSubTitle:
                '{cashOutAmount} has been requested to be sent to your bank account ending in {accountEnding}. They usually take around 2 business days to reflect in your bank account.',
            bankStatementReviewConfirmationSubTitle:
                'A Cash Out of {cashOutAmount} has been submitted. We are processing your request and expect it to take 10 days to initiate the payment to your financial institution.',
            doneCta: 'Done',
            confirmCashOutCta: 'Confirm Cash Out',
            tryAnotherAccount: 'Sorry, something went wrong. Please select a different account and try again.',
            genericError: 'Sorry, something went wrong. Our team was notified and are quickly trying to resolve the issue.',
            unsupportedFileFormatError: 'Sorry, that format is not supported. Please upload an image or PDF.',
            cashOutViaPlaidConfirmationSubTitle: 'We will transfer {cashOutAmount} to your bank account ending in {accountEnding}.',
            plaidAccountsAdded: 'Your accounts have been added. Please select an account for cash out',
            accountTypeConfirmation: 'Please confirm if this is a personal or business bank account.',
            cashOutViaBankStmtUploadConfirmationSubTitle: 'A Cash Out of {cashOutAmount} to your bank account ending in {accountEnding} will be reviewed and initialized once approved.',
            cashOutChunkExplanationSubTitle: 'For security, your funds will be transferred as {numberOfCashOutChunks} deposits due to a daily maximum transfer of {formattedMaxDailyBtPlusCashOuts}.',
            cashOutChunkExplanationFootnote:
                '1. For security reasons, there is a {formattedMaxDailyBtPlusCashOuts} daily maximum for combined Cash Outs and Balance Transfers. Amounts over {formattedMaxDailyBtPlusCashOuts} will automatically be broken into multiple deposits over a few days.',
            // In practice, we won't have 10 cash out chunks w/ line sizes up to $250K & max daily limit of $50K, but
            // this helps future-proof a bit.
            // Todo Factor into a shared method converting numbers to strings
            firstChunkLabel: 'First Deposit',
            secondChunkLabel: 'Second Deposit',
            thirdChunkLabel: 'Third Deposit',
            fourthChunkLabel: 'Fourth Deposit',
            fifthChunkLabel: 'Fifth Deposit',
            sixthChunkLabel: 'Sixth Deposit',
            seventhChunkLabel: 'Seventh Deposit',
            eighthChunkLabel: 'Eighth Deposit',
            ninthChunkLabel: 'Ninth Deposit',
            tenthChunkLabel: 'Tenth Deposit',
            includingFeeSubText: 'incl. {formattedFee} fee',
            recentFullDrawCashOutSubtext:
                'Your first deposit will be available within 2 business days, up to {formattedMaxDailyBtPlusCashOuts}. Additional funds will follow in subsequent daily deposits until fully transferred.',
            pendingCashOutListSubtext:
                'We have sent the request to your bank. It typically takes 2 business days to reflect in your bank account. If you do not see it after that, please contact your bank.',
            cardNotActivatedMessage: 'For your security, please activate your card before you {featureName}. If your card is still in the mail, please wait until you get the card. ',
            stillInRescissionMessage: 'Sorry, you are currently within your 3-day rescission period. Please try again after {rescissionEndDate}.',
            whyCashOutTitle: 'Why Cash Out?',
            whyCashOutText:
                'With Aven’s Cash Out feature, you can transfer cash from your Aven account. This is great when you can’t use your Aven credit card, need to make a cash payment, or need the funds in your bank account.',
            areThereFeesTitle: 'Are there any fees?',
            areThereFeesText: 'There is a simple {cashOutFee} fee on the amount being sent to your bank.',
            howLongTitle: 'How long does it take?',
            howLongText: `It usually takes 2 business days for the funds to be available in your bank account.`,
            howToPayBackTitle: 'How do I pay it back?',
            howToPayBackFixedFinanceChargeText: `
                <p class="mb-3">You have two options for monthly payments: a credit card minimum payment or a fixed monthly payment.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you cash out + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Set up fixed monthly payments – no interest, just a fixed monthly fee. You can lock in your payments for 5 or 10 years. Your fixed rate is {fixedInstallmentApr}. Once started, your payments and rate will NOT change for the entire length of the plan.</p>
           `,
            howToPayBackSimpleInterestText: `
                <p class="mb-3">You have two options for monthly payments: a credit card minimum payment or a fixed monthly payment.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you cash out + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Lock in a fixed monthly payment for 5 or 10 years. Your fixed rate is based on your current Aven APR + a {fixedInstallmentAprPremium} premium to lock in the rate. Once started, your payments and rate will NOT change for the entire length of the plan.</p>
           `,
            howToPayBackTextHcmLimit: `
                <p class="mb-3">Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you cash out + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
           `,
            limitForCashOutsTitle: 'Is there a limit for cash outs?',
            limitForCashOutText: `There is a ${generic.prettyPrintDollarAmount(String(MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS))} daily limit on cash outs.`,

            fixedInstallmentVsRevolvingPlanTitle: 'Fixed Installment vs. Revolving Plan?',
            fixedFinanceChargeVsRevolvingPlanText: `
                <p class="mb-3">You have two options for monthly payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you cash out + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Set up fixed monthly payments – no interest, just a fixed monthly fee. You can lock in your payments for 5 or 10 years. Your fixed rate is {fixedInstallmentApr}%. Once started, your payments and rate will NOT change for the entire length of the plan.</p>
            `,
            SimpleInterestLoanVsRevolvingPlanText: `
            <p class="mb-3">You have two options for monthly payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you cash out + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Lock in a fixed monthly payment for 5 or 10 years. Your fixed rate is based on your current Aven APR + a {fixedInstallmentAprPremium} premium to lock in the rate. Once started, your payments and rate will NOT change for the entire length of the plan.</p>
            `,
            SimpleInterestLongTermLoanVsRevolvingPlanText: `
            <p class="mb-3">You have two options for monthly payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you cash out + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Lock in a fixed monthly payment. Your fixed rate is based on your current Aven APR + a premium to lock in the rate. Once started, your payments and rate will NOT change for the entire length of the plan.</p>
            `,
            fixedInstallmentNotAvailableTitle: 'Why Is Fixed Not Available?',
            fixedInstallmentNotAvailableDueToHCMText:
                'Your APR for our fixed monthly payment (Aven Simple Loan) exceeds the High-Cost Mortgage limit set by regulation (CFPB § 1026.32). If the Fed lowers interest rates, this feature may be available in the future.',
            fixedInstallmentNotAvailableDueToTeaserText: 'Fixed monthly payment (Aven Simple Loan) are not available during the {cashOutPromoAprPercentText} intro APR period.',
            fixedInstallmentNotAvailableDueToPendingPrimeV2OptOut: 'Fixed monthly payment (Aven Simple Loan) are not available until your prime opt-out request is completed.',
            cashOutRequestedTitle: 'All Set! Your {cashOutAmount} cash out is being processed.',
            cashOutRequestProcessedTomorrowSubtitle: `You'll get a confirmation message tomorrow morning. `,
            cashOutRequestedSubtitle: 'The deposit will be available within <b style="color: #4f4f4f">2 business days</b>.',
            cashOutRequestedSubtitleFullDraw:
                'Your first deposit will be available within <b style="color: #4f4f4f">2 business days, up to {formattedMaxDailyBtPlusCashOuts}</b>. Additional funds will follow in subsequent daily deposits until fully transferred.',
            cashOutPartialInterestDisclosure: "<b>Note</b>: Your first payment may be lower than the following monthly payments due to only paying a partial month's interest.",
            cashOutBankStatementImgAltText: 'Contact your bank for your routing and account number',
            cashOutAutoPayStatementBalanceWarning: `<b>Note</b>: You have AutoPay set to pay the full Statement Balance, which will include this full CashOut on your next statement. <a class='text-underline' href='/autoPay' target='_blank'>Update AutoPay Settings</a> if you do not want to pay it off in full next statement.`,
            cashOutAutoPayOptInFailed: `Unable to register minimum AutoPay. Please try again at <a class='text-underline' href='/autoPay' target='_blank'>Update AutoPay Settings</a> to set up AutoPay.`,
            cashOutAutoPayOptInSuccess: 'The Minimum Due will automatically be deducted from your bank account ending in {last4AccountNumber} on the due date.',
        },
        autoPayModal: {
            title: 'AutoPay',
            body: 'For peace of mind, set up AutoPay for the minimum payment amount so you don’t forget to make a payment – from your bank account ending in {accountEnding}.',
            turnOnButton: 'Turn On Minimum AutoPay',
            setUpLaterButton: 'Set Up Later',
            autoPayOptInFailed: `Unable to register minimum AutoPay. Please try again at <a class='text-underline' href='/autoPay' target='_blank'>Update AutoPay Settings</a> to set up AutoPay.`,
        },
        cashOutById: {
            cancelTransactionCta: 'Cancel Transaction',
            confirmationMessage: 'Your transaction was successfully canceled.',
        },
        cashOutRequest: {
            title: 'Cash Out',
        },
        securityConfirmation: {
            title: 'Security Confirmation',
            description: 'Confirm the last 4 digits of your social security',
            ssnPlaceholder: 'Last 4 Social Security Number',
            ssnInvalid: 'Please check the last 4 digits of your social security number',
            dateOfBirthInvalid: 'Please check your date of birth',
            ssnOrDobInvalid: 'Please check the last 4 digits of your social security number and your date of birth',
            dateOfBirthPlaceholder: 'Date of Birth MM/DD/YYYY',
        },
        balanceTransferById: {
            cancelTransactionCta: 'Cancel Transaction',
            confirmationMessage: 'Your transaction was successfully canceled.',
            cancelatonRequestError: 'We were unable to cancel your balance transfer because it was already processed.',
        },
        bankConnect: {
            tryAgain: 'Unable to link bank account. Please try again or try another bank account. If the problem persists, please contact customer service for assistance.',
            loadingTitle: 'Processing...<br /> This could take up to a minute.',
        },
        rewards: {
            title: 'Rewards',
            redeemTitle: 'Redeem Cash Back',
            redeemCashBackCta: 'Redeem Cash Back',
            redeemTravelCta: 'Redeem for Travel',
            listTitle: 'Redeemed',
            redeem: {
                title: 'Redeem Points',
                btnCta: 'Redeem',
            },
            redeemedTitle: 'Rewards Redeemed',
            inputLabel: 'You have {points} points available to redeem',
            inputPlaceholder: '1,000',
            cashbackAmountText: 'Cashback Amount',
            availablePoints: 'Available Points',
            pointsMultipliers: {
                hotels: 'hotels',
                flights: 'flights',
                other: 'everything',
            },
            useYourPoints: {
                sectionTitle: 'Use & Earn Rewards',
                travelAndGifts: {
                    cardTitle: 'Travel and Gifts',
                    cardBody: 'Visit the Aven Rewards portal and use reward points for Hotels, Flights, and gifts.',
                },
                flights: {
                    cardTitle: 'Book a Flight',
                    cardBody: 'Get away for a while. Book flights through the Aven Travel Portal using your rewards points today.',
                },
                hotels: {
                    cardTitle: 'Find a Hotel',
                    cardBody: 'Book a getaway using your Aven Rewards Points.',
                },
                cashBack: {
                    cardTitle: 'Cash Back',
                },
                giftCards: {
                    cardTitle: 'Gift Cards',
                },
            },
            offers: {
                sectionTitle: 'Offers',
                cardTitle: 'Earn {formattedCashBackRatio} on everything<sup>1</sup>',
                cardBody: 'Set up AutoPay to earn unlimited {formattedCashBackRatio} cash back on everything',
            },
            footnote1:
                '1. Cashback available to those who enroll in AutoPay, refer to <a href="https://www.aven.com/docs/AutoPayAgreement.pdf">AutoPay Terms & Conditions</a>. Cashback applies to valid purchases (net of refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, cash-outs or reward redemptions. Cashback is accumulated as points and redeemable as statement credit or in the travel portal. Refer to <a href="{cashbackTermsAndConditionsLink}">Rewards Terms & Conditions</a>.',
            genericError:
                'There was an error during rewards redemption. Please refresh the page and try again. If this problem persists, please reach out to our support team for assistance with your rewards redemption.',
            ineligibleSecondaryCard: 'To redeem points, please have the primary cardholder login into their account.',
            redeemMoreThanRemainingError: 'Max points available for redemption: {maxPointsAvailableForRedemption}',
            coreCardEndOfDayProcessingError: 'Rewards redemption is currently unavailable due to system maintenance. Please retry in 2 hours.',
            enrollInAutoPayForCashBackBanner: '{cashbackPercentWithAutopay}% UNLIMITED CASH BACK</br>Set up recurring payments (AutoPay) to earn unlimited {cashbackPercentWithAutopay}% cash back.',
        },
        autoLoanRefinance: {
            activity: {
                title: 'Auto Refi',
                monthlyPayment: 'Next Monthly Payment',
            },
            launchPoint: {
                title: 'Refinance your existing auto loan',
                shortTitle: 'Auto Loan Refinance',
                subTitle: 'Save money and manage your payments with ease in as fast as 15 minutes.',
                shortSubTitle: 'Refinance your auto loan and save money.',
                cta: 'Refinance',
            },
        },
        payItForward: {
            title: 'Pay It Forward',
            copyLink: 'COPY',
            linkCopied: 'COPIED',
            getShareLinkError: 'There was an error getting your personal invite link. Please refresh and try again.',
            options: {
                textMsg: 'Text Message',
                email: 'Email',
                shareOnTwitter: 'Share On Twitter',
                shareOnFacebook: 'Share On Facebook',
                copyLink: 'Copy Invite Link',
                moreOptions: 'More Options',
            },
            smsMessage: 'Using the Aven Card for homeowners and loving it. Join with my code for {maxAmount} credit. Visit {pifLink}', //sms max length per message is 160, current string max length is ~153. Be careful with special characters since they convert the sms to unicode which reduces the max length to 70.
            smsMessageForRemind: 'Reminding you about the Aven Card for homeowners. Join with my code for {maxAmount} credit! Visit {pifLink}',
            twitterShareText: `I’ve been using the Aven Card for homeowners and love it. Join with my code and get {maxAmount} in credit.`,
            goToLink: 'Go to {link}',
            shareWithContacts: 'Share with contacts instead',
            classicPif: {
                description: 'Share the message with friends. We give them {maxAmount}. They may share part of it back with you!',
                terms: "For more details, see <a href='https://aven.com/docs/PifTerms.pdf' target='_blank'>Pay It Forward Terms.</a>",
                altTitle: 'Share a {maxAmount} Gift – On Us',
                descriptionCard: 'We give them {maxAmount}. They may share part of it back!',
                descriptionPage: 'Share the message with friends. We give them {maxAmount}. They may share part of it back with you!',
                smsMessage: 'Using the Aven Card for homeowners and loving it. Join with my code for {maxAmount} credit. Visit {pifLink}', //sms max length per message is 160, current string max length is ~153. Be careful with special characters since they convert the sms to unicode which reduces the max length to 70.
                smsMessageForRemind: 'Reminding you about the Aven Card for homeowners. Join with my code for {maxAmount} credit! Visit {pifLink}',
                twitterShareText: `I’ve been using the Aven Card for homeowners and love it. Join with my code and get {maxAmount} in credit.`,
            },
            travelRewardsV0: {
                description: 'Share Aven with friends and they get two tickets when they sign up.',
                terms: "For more details, see <a href='https://aven.com/docs/PifTravelTerms.pdf' target='_blank'>Pay It Forward Terms.</a>",
                altTitle: 'Share Two Tickets To Vegas – On Us',
                descriptionCard: 'Share Aven with friends and they get two tickets when they sign up.',
                descriptionPage: 'Share Aven with friends and they get two tickets when they sign up.',
                smsMessage: 'Using the Aven Card for homeowners and loving it. Join with my code for two tickets to Las Vegas. Visit {pifLink}', //sms max length per message is 160, current string max length is ~153. Be careful with special characters since they convert the sms to unicode which reduces the max length to 70.
                smsMessageForRemind: 'Reminding you about the Aven Card for homeowners. Join with my code for two tickets to Las Vegas! Visit {pifLink}',
                twitterShareText: `I’ve been using the Aven Card for homeowners and love it. Join with my code for two tickets to Las Vegas.`,
            },
            travelTicketsBahamas: {
                description: 'Share Aven with friends and they get two tickets when they sign up.',
                terms: "For more details, see <a href='https://aven.com/docs/PifTravelTerms.pdf' target='_blank'>Pay It Forward Terms.</a>",
                altTitle: 'Share Two Tickets to the Bahamas – On Us',
                descriptionCard: 'Share Aven with friends and they get two tickets to the Bahamas when they sign up.',
                descriptionPage: 'Share Aven with friends and they get two tickets to the Bahamas when they sign up.',
                smsMessage: 'Using the Aven Card for homeowners and loving it. Join with my code for two tickets to the Bahamas. Visit {pifLink}', //sms max length per message is 160, current string max length is ~153. Be careful with special characters since they convert the sms to unicode which reduces the max length to 70.
                smsMessageForRemind: 'Reminding you about the Aven Card for homeowners. Join with my code for two tickets to the Bahamas! Visit {pifLink}',
                twitterShareText: `I’ve been using the Aven Card for homeowners and love it. Join with my code for two tickets to the Bahamas.`,
            },
        },
        becomeAmbassador: {
            askQuestionStep: {
                title: 'We think you would be a great Aven brand ambassador. <strong>Get $100</strong> in statement credit.',
                subtitle: 'HOW IT WORKS',
                contentItem1: 'Allow sharing your contacts. <strong>We will not reach out to them directly, this is just to know if a friend signs up.</strong>',
                contentItem2: 'Your first name may appear in our marketing, mail and app. Some examples:',
                acceptButtonText: 'Accept Invite',
                declineButtonText: 'No Thanks',
            },
            confirmationStep: {
                content: 'Thank you for becoming an Aven ambassador! $100 will be added as statement credit.',
                continueButtonText: 'Continue',
            },
            acceptToBeAmbassadorError: 'There was an error accepting to be an ambassador. Please try again later.',
        },
        callBooking: {
            title: 'Schedule a Call',
            problemWithPaymentTitle: 'There was a problem processing payment, let’s schedule a call.',
            problemWithPaymentSubtitle: 'Schedule a call so we can help set up a plan. Not doing so can start to affect your credit – it gets reported to the Credit Bureau automatically each month.',
            problemAddDebitCardTitle: 'There was a problem adding your debit card, let’s schedule a call.',
            problemAddDebitCardSubtitle: 'Schedule a call so we can help set up a plan. Not doing so can start to affect your credit – it gets reported to the Credit Bureau automatically each month.',
            getBackOnTrackTitle: 'Oh no! Let’s get on a call.',
            getBackOnTrackSubtitle:
                'We are sorry you are having trouble making a payment. Let’s get on a call so we can help you make a payment and remove the late fee. Please select a time for us to call you.',
        },
        payItForwardContactList: {
            classicPif: {
                title: '<b>Invite friends.</b> They get {pifBonus} and may share part of it back with you!',
                checkboxTitle: '<b>Invite {maxAllowed} friends.</b> They get {pifBonus} and may share part of it back with you!',
                invite: 'Share {pifBonus}',
            },
            travelRewardsV0: {
                title: '<b>Invite friends.</b> They get two tickets to Las Vegas!',
                checkboxTitle: '<b>Share Two Tickets to Las Vegas</b> Share Aven with friends and they get two tickets when they sign up. ',
                invite: 'Share Tickets',
            },
            travelTicketsBahamas: {
                title: '<b>Invite friends.</b> They get two tickets to the Bahamas!',
                checkboxTitle: '<b>Share Two Tickets to the Bahamas</b> Share Aven with friends and they get two tickets when they sign up. ',
                invite: 'Share Tickets',
            },
            remind: 'Remind friend | Remind friends',
            searchPlaceholder: 'Enter Name or Phone',
            infoModalTitle: 'About Pay It Forward',
            infoModalClose: 'Close',
        },
        payItForwardInfoModal: {
            title: 'About Pay It Forward',
            close: 'Close',
            classicPif: {
                content:
                    'When they sign up, they\'ll get {pifBonus} from us and may share part of it back with you! For more details, see <a href="https://aven.com/docs/PifTerms.pdf" target="_blank" class="text-primary text-underline">Pay It Forward Terms</a>',
            },
            travelRewardsV0: {
                content:
                    'When they sign up, they\'ll get two tickets to Las Vegas from us! For more details, see <a href="https://www.aven.com/public/docs/PifTravelTerms" target="_blank" class="text-primary text-underline">Pay It Forward Terms</a>',
            },
            travelTicketsBahamas: {
                content:
                    'When they sign up, they\'ll get two tickets to the Bahamas from us! For more details, see <a href="https://www.aven.com/public/docs/PifTravelTerms" target="_blank" class="text-primary text-underline">Pay It Forward Terms</a>',
            },
        },
        payItForwardTipping: {
            description: 'Because your friend {sourceName}, introduced you to Aven we are gifting you a {maxAmount} bonus. Do you want to thank them with {defaultAmountPct}?',
            customTipDescription: 'Because your friend {sourceName}, introduced you, we are gifting you a {maxAmount} bonus. Would you like to share ${defaultAmount} of it with them?',
            tippedSourceMessage: 'Great, thanks for sharing!',
            pickTip: 'PICK A TIP',
            otherAmount: 'Other Amount',
            noTip: `Don’t Thank`,
            cta: 'Confirm',
            noTipMessage: 'Welcome to Aven!</br>Enjoy the bonus 🎉',
            tipMessage: 'Great, thanks for sharing!',
            terms: "By accepting the bonus you agree to the <a class='text-underline text-muted' href='https://aven.com/docs/PifTerms.pdf' target='_blank'>Bonus Terms</a>.",
        },
        signOnBonus: {
            description: 'Because your fantastic realtor, <strong>{sourceName}</strong>, introduced you to Aven we are gifting you a <strong>{maxAmount}</strong> bonus as a statement credit.',
            acknowledged: 'Welcome to Aven!<br>Enjoy the bonus 🎉',
        },
        closeAccount: {
            title: 'Close Account',
            prompt: {
                text: `What is the main reason for closing? Let's see if we can do something about it!`,
                submit: `Submit`,
            },
            permanentClose: {
                title: 'Close Permanently',
                subText1: 'When you close the account, we will close the Aven card account. You will have to re-apply in case you want an Aven card.',
                subText2: 'The county can take up to 14 days from account being fully paid off to record the lien reconvenyance.',
                buttonText: 'Continue',
            },
            keepCard: {
                text: 'Would you like to keep your Aven Card open for the future?',
                subText:
                    'The lien will be removed and the line will be reduced to {limitedModeCreditLimit} until you are ready to easily reactivate the full Aven Card. <b>No need to reapply from scratch.</b>',
                yesBtnText: 'Remove Lien & Keep Card',
                noBtnText: 'No, Close Permanently',
            },
            wireTransfer: {
                confirmWireText: 'Confirm Wire Will Be Initiated',
                confirmWireSubText: 'I confirm I will initiate the wire transfer from my bank account for {amount} and will include the additional note for reference.',
                confirmCloseText: 'Confirm Close',
                confirmCloseSubText: 'By checking the box, I understand by continuing my account will be closed, and I will have to submit a new application to open an Aven card.',
                nothingToDoText: 'You are all set! We are working on our end to finalize everything. We will keep you updated.',
            },
            countyRecordingFee: {
                title: 'County Recording Fee',
                text: 'Your county charged a recording fee when you joined, which we added to your initial balance as a 12 month installment plan. The remaining amount is included in the payoff.',
            },
        },
        closeAccountMofoUpsell: {
            title: 'Check if you qualify for an upgrade in just a tap and with no impact on your credit score.',
            perk1: '1. Lower APR',
            perk2: '2. Higher Credit Limit',
            perk3: '3. Lower Monthly Payment',
        },
        emailPayoff: {
            title: 'Email Payoff Documents',
            placeholder: 'Email Address',
            addEmail: 'Add Other Email',
            sendConfirmTitle: 'Email Sent',
            sendConfirmText: 'We have sent the Payoff documents in an email to <b>{emails}</b>',
            sendError: 'Failed to send Payoff document to emails',
        },
        payoffQuote: {
            text: 'If you do not initiate the wire transfer by the end of the due date ({tomorrow}), please return here to get the latest amount.',
            zeroBalancePayoff: 'You currently don’t have an outstanding balance and have nothing to pay off.',
            errors: {
                genericTitle: 'Payoff Details Failed',
                genericDesc: 'Sorry, something went wrong. Please try again.',
                payoffDateBeyondStatementTitle: 'Invalid Payoff Date',
                payoffDateBeyondStatementDesc: 'Sorry, we could not generate your Payoff quote because tomorrow is the beginning of your new statement cycle. Please try tomorrow again.',
            },
        },
        retention: {
            homeReAttach: {
                loadingText: 'Crunching numbers. We’ll redirect you to our online application.',
                continueOriginationText: 'We crunched some numbers and have an offer ready.',
                differentHomeReAttachText: 'Congrats on the new home! In order to attach a new home, we’ll redirect you to our online application.',
            },
        },
        TransactionById: {
            title: 'Report a Problem',
            reportAProblemCta: 'Report a Problem',
            continueCta: 'Continue',
            submitDisputeCta: 'Submit',
            success: {
                successText: 'Thank you for your submission',
                successSubText: 'Our dispute team will email you within 5 business days. If the issue is resolved in your favor, provisional credit will be processed.',
                disclaimer:
                    'Please note: The dispute is not considered submitted till all required documents are received by Aven. If we request documents or evidence, please respond promptly. Aven reserves the right to reject disputes if documents or responses are not received within 15 days of request.',
                doneCta: 'Done',
                disputeSubmittedAltText: 'Dispute submitted',
            },
            error: {
                errorText: 'Oh no, something went wrong. Our team has been notified.',
            },
            status: 'Status',
            startDate: 'Date Reported',
            closeDate: 'Date Resolved',
            contactMerchantAltText: 'Contact Merchant',
            contactMerchant: {
                text: 'Have you contacted the merchant?',
                subText: 'Often, the fastest way to resolution is by contacting the merchant directly. If that does not work, we can file a dispute with VISA, which typically takes 60-90 days.',
            },
            contactMerchantButtonCta: 'I’ll Contact The Merchant',
            fileADisputeButtonCta: 'File A Dispute',
            reviewText: 'Aven is reviewing your dispute and will communicate with you via email. It could take up to 90 days to resolve.',
            rejectedReviewText: 'We emailed a document to further explain why this dispute was rejected.',
            cancelButtonCta: 'Cancel Dispute',
            cancelDisputeModalTitle: 'Are you sure?',
            cancelDisputeModalText: 'Are you sure you want to cancel this dispute? This will stop the investigation.',
            cancelDisputeConfirmButtonCta: 'Yes, Cancel Dispute',
        },
        SingleScheduledPayment: {
            scheduledPaymentTitle: 'Scheduled Payment',
            cancelPaymentTitle: 'Cancel Payment',
            cancelPaymentCta: 'Cancel Payment',
            cancelPaymentConfirmationCta: 'Cancel Scheduled Payment',
            keepPaymentCta: 'Keep Payment',
            doneCta: 'Done',
            fromAccountEndingIn: 'From account ending in {mask}',
            yourScheduledPaymentHasBeenCancelled: 'Your scheduled payment has been cancelled.',
            areYouSureYouWantToCancel: 'Are you sure you want to cancel your scheduled payment? If you do, make sure to make the payment manually to avoid missing a payment.',
        },
        promoLandingPage: {
            unavailable: 'This promotion is no longer available.',
        },
        pointLandingPage: {
            unavailable: 'This offer is no longer available.',
            pageTitle: 'Get Back On Track',
            goalText: 'We’ve partnered with Point, a Home Equity Investment product that gives you cash in exchange for sharing a portion of your future home equity. $0 monthly payments.',
            pointExplainedText: 'Unlike debt, there are no monthly payments, so it can be helpful when you are short of cash and need to get back on track.',
            benifitTitle: 'Point Benefits',
            benefitLine1: 'No monthly payments, ever.',
            benefitLine2: 'Get back on track financially without foreclosure.',
            benefitLine3: 'Buy back your equity anytime within 30 years. No penalty.',
            benefitLine4: 'Maintain complete control over your home.',
            ctaText: 'Check Your Offer Now',
            loading: 'Loading',
        },
        hcmRemediationOffer: {
            title: 'Interest Rate Adjustment Options',
            whatHappened: '<b>What Happened:</b> We noticed that the rate on your Aven Card was higher than what it should have been.',
            goodNews:
                '<b>Good news:</b> We have temporarily adjusted your account from the higher rate to the correct rate. We have refunded all late fees, if any, charged on your account. To make the temporary adjustment of rate permanent, and to get a refund of excess interest paid, please click on the “Confirm Now” button below. We will also provide an additional $100 statement credit if you confirm these changes. These changes and the credit of $100 will be completed within 30 days of your confirmation.',
            how: '<b>How:</b> Click the button below to confirm. Your account will be updated permanently and refunded within 30 days.',
            uploadedLetter: 'We have also uploaded a letter with all the details in the document section of your account portal.',
            notInterested:
                '<b>Not Interested?</b> You have the option to decline these changes with the button below. If you decline, your account will stay at the higher rate, and you will not receive any refund for past interest paid. We will not provide the $100 statement credit if you pick this option. Future late fees will still be turned off and you will need to attend an online Mortgage Counseling course.',
            ps: '<b>P.S.</b> We prefer to reduce your rate, give you a refund, and provide a credit of $100. Just click the Confirm Now button below.',
            confirmNow: 'Confirm Now',
            decline: 'Decline',
            skip: 'Skip Answer For Now',
        },
        acceptHcmRemediation: {
            title: 'Great, your lower rate and refund have been confirmed',
            refundDetails:
                'Your account will be permanently updated from the higher rate to the correct rate and credited with {totalCreditIncludingAdditionCreditInDollars} for the excess interest, any late fees and the extra {additionalStatementCreditInDollars}. This should reflect in your account within 30 days.',
            nothingToBeDone: 'Nothing else needs to be done. Thank you for being a valued Aven customer.',
        },
        declineHcmRemediation: {
            title: 'Decline Confirmed',
            declineDetails: 'You have declined the changes. We will contact you shortly regarding the High Cost Mortgage Counseling course.',
        },
        simpleInterestConversionInterstitial: {
            constantPaymentTitle: 'Upgrade Account — Save Up To {savings}',
            constantPaymentContent: `We're upgrading Aven's fixed payment plans! Save up to {savings} over the duration of your loan.`,
            lowerPaymentTitle: 'Lower your monthly payment by {monthlyPaymentDecrease} and save {savings} in total',
            lowerPaymentContent: 'With our upgraded fixed payment plans, lower your monthly payment by {monthlyPaymentDecrease} and save {savings} over the duration of your loan.',
            learnMore: 'Learn More',
            skip: 'Not Now',
        },
        simpleInterestLoanConversion: {
            constantPaymentTitle: `<strong>Save {totalSavedByConverting}</strong> when you upgrade your fixed payment plans`,
            constantPaymentConversionOffer: `Reduce the number of outstanding payments, decrease your monthly payments.<br/><br/>Save over the duration of your plan with regular on time payments. <strong>Your interest rate does NOT increase with this upgrade.</strong>`,
            lowerPaymentTitle: 'Lower your monthly payment by {monthlyPaymentDecrease} and save {totalSavedByConverting} in total when upgrading your fixed payment plan',
            lowerPaymentContent:
                'Decrease your monthly payments and save over the duration of your plan with regular on time payments. <strong>Your interest rate does NOT increase with this upgrade.</strong>',
            accept: 'Accept',
            noThanks: 'No Thanks',
        },
        acceptSimpleInterestLoanConversion: {
            title: 'Upgrade Successful!',
            upgradeDetails: `Your Fixed Payment Plan(s) have been converted to Aven Simple Loan Plan(s).`,
            gotIt: 'Got It',
        },
        declineSimpleInterestLoanConversion: {
            body: 'No problem! Would you like to schedule a call with one of our team members to clarify anything? We value you as a customer and would love to hear from you.',
            scheduleCall: 'Schedule Call',
            noThanks: 'No Thanks',
        },
        simpleInterestLoanConversionIneligible: {
            title: 'Aven Simple Loans',
            dqErrorMessage: 'Your account is currently past due. Please get current before upgrading.',
            gotIt: 'Got It',
        },
        getBackOnTrack: {
            title: 'Get Back On Track',
            questions: {
                reasonText: 'We want to help. Why are you late?',
                daysToPayText: 'How soon do you think you can start making your regular payments again?',
                breakUpPaymentText: 'Do you think you could make any of the following payments if we break it up?',
                maxPaymentText: 'We understand and want to help. What is the max payment you can make?',
                payNowOrLaterText: 'Pay now and we will waive the late fee. You can set the payment date up to {autopayMaxDays} days from today.',
                otherDaysPlaceholderText: 'How many days?',
            },
            letsMakeAPayment: 'Thanks, let’s make a payment',
            thankYou: `Thank You!`,
            makePaymentNowButtonText: 'Schedule a Payment',
            payLaterButtonText: 'Pay Later with Late Fee',
            cantPayRightNow: "I Can't Pay Right Now",
            genericError: 'Something went wrong. Please try again.',
            plaidGenericError: 'We require additional information to complete the transaction',
            signIntoBankText: 'Our team will review and reach out with possible solutions. To help us calculate the lowest amount you can pay, sign in to your bank. We don’t store your credentials.',
            thankYouText: 'We appreciate you submitting this information. Our team will review and reach out with possible solutions to help you get back on track within 3 business days.',
            errors: {
                invalidReason: 'Please select a reason',
                invalidDaysToPay: 'Please select a number of days',
                invalidPaymentOption: 'Please select a payment option',
                invalidMaxPayment: 'Please enter a valid amount',
            },
        },
        GetHelpPaymentOptions: {
            title: 'Get Help',
            ifYouPayAmountToday: 'If you pay {amount} today, we will bring your account current (no longer past due) and reverse late fees. You will need to make regular payments to stay current.',
            dqReasonPrompt: 'To help us understand, please select the reason you are late.',
            payNow: `Pay Now`,
            continue: `Continue`,
            amount: 'Amount',
            payFrom: 'Pay From',
            reageEligibilityDisclaimer: 'Only one hardship adjustment (including the option above) allowed every 12 months, up to a max of twice in account lifetime.',
            legalText:
                'If you make your payment before 7:59 PM (EST) we will initiate the payment on the same day, but it might take a few days to have the funds withdrawn from your account and your available balance to be updated.',
            checkboxPrompt: 'I agree to keep my account current after receiving this hardship adjustment by making minimum due payments on time.',
            paymentSuccessful: 'Payment Successful',
            thankYouForWorkingWithUs:
                'Thank you for working with us to help get you back on track. Your account will be made current and reverse late fees. You will need to make regular payments to stay current.',
            done: 'Done',
        },
        autoPaySurvey: {
            title: 'Autopay Survey',
            questions: {
                reasonText: '{name}, why do you not have autopay turned on?',
            },
            thankYou: `Thank You!`,
            errors: {
                invalidReason: 'Please select a reason',
            },
        },
        autoPayEnrolleeFeedbackSurvey: {
            title: 'Autopay Survey',
            questions: {
                reasonText: '{name}, why do you have autopay turned on?',
            },
            thankYou: `Thank You!`,
            errors: {
                invalidReason: 'Please select a reason',
            },
        },
        acceptTermsOfService: {
            thanksText: 'You have agreed to the latest Terms of Service. Thank you for being a valued Aven Card holder!',
        },
        refiSurvey: {
            cardTitle: 'Interested in refinancing?',
            title: 'Cash-Out Refinance',
            reasonQuestion: 'I am interested in',
            howMuchBorrowQuestion: 'How much would you like to borrow?',
            howMuchMonthlyPaymentQuestion: 'How much would you like to pay monthly?',
            mortgageQuestion: 'Would you be interested in a cash-out refinance of your primary mortgage?',
            interestedInMortgagePartnerThankYouMsg: "Thanks for sharing! We've contacted our network of mortgage providers and will be emailing you shortly.",
            generalThankYouMsg: "Thanks for your response, we'll get back to you soon!",
        },
        AdcOverride: {
            headerTitle: 'Confirm Activity',
            recentTransactions: 'Recent Transactions',
            wasThisYou: 'Were you attempting to make a purchase for {transactionAmount} at {merchantName}?',
            overrideExpired: 'Transactions can be explicitly allowed within 30 minutes. Please try your transaction again if you would like to allow it.',
            validateLast4Ssn: 'To confirm it was you, please enter the last 4 digits of your social security number',
            incorrectSsnWarning: 'The last 4 digits supplied do not match our records. Please try again.',
            thanksForConfirming: 'Great! Please try your transaction again within the next hour.',
            weHaveLockedYourCard: 'For your security, we locked your card.  Review recent transactions and select an action below.',
            yourCardWillStayLocked: 'Your card will stay locked. You can always unlock or report your card lost or stolen in Settings.',
            yourCardIsNowUnlocked: 'Your card is now unlocked. If you see any suspicious activity, you can report your card lost or stolen.',
            confirmPassword: 'Confirm Password',
            yesThisWasMe: 'Yes, This Was Me',
            noThisWasNotMe: 'No, This Was Not Me',
            keepCardLocked: 'Keep Card Locked',
            unlockCard: 'Unlock Card',
            gotIt: 'Got It',
            confirm: 'Confirm',
            cancel: 'Cancel',
            errors: {
                expired: 'We can no longer accept a response for this issue.',
                alreadyAnswered: 'An response has already been received for this issue.',
                ssnIncorrectCardFrozen: 'For your security, we locked your card. You can always unlock or report your card lost or stolen in Settings.',
            },
        },
        AdvanceTransactionNotification: {
            headerTitle: 'Scheduled Purchases',
            createAtnTitle: 'Schedule Large Purchases',
            createAtnExplanation: 'For your security, you can schedule your large purchases to make sure they go through at the point of sale.',
            scheduleLargePurchases: 'Schedule Large Purchases',
            chooseDatesAndAmount: 'Choose a date range and the expected spend amount.',
            startDate: 'Start Date',
            endDate: 'End Date',
            expectedSpend: 'Expected Spend',
            cancel: 'Cancel',
            submit: 'Submit',
            cancelSchedulePurchase: 'Cancel Scheduled Purchase',
            cancelConfirmation: 'Are you sure you want to cancel your scheduled limit increase?',
            cancelScheduledPurchase: 'Cancel Scheduled Purchase',
            keepScheduledPurchase: 'Keep Scheduled Purchase',
            error: {
                notEligible: `Unfortunately, this feature is not yet available to you.`,
            },
        },
    },
    components: {
        formSelectAutocomplete: {
            noOptions: 'No results found for',
        },
        formFieldAddress: {
            placeholder: {
                completeAddress: 'Street Address, City, State',
                street: 'Street Address',
                unit: 'Unit #',
                city: 'City',
                state: 'State',
                postalCode: 'Zip',
            },
            enterAddressManually: 'Enter address manually',
            validation: {
                addressNotFound: 'Address Not Found. Please check your input.',
                CAResidentOnly: 'Must be a resident of California',
            },
            googleError: 'Could not find valid location, please try again',
        },
        accountStatusBanner: {
            ctaPayNow: 'Pay Now',
            pastDue: {
                title: 'Account Past Due',
                description: 'Please pay the outstanding balance ({amount}) to get back on track.',
            },
            delinquent: {
                /*
                    D1 = delinquency < 30 days
                    D2 = 30 - 59
                    D3 = 60 - 179
                    D4 = 180+
                 */
                notBlocked: {
                    title: 'Account Past Due',
                    description: 'Please pay the past due amount of {amount}',
                },
                blocked: {
                    title: 'Account Past Due',
                    description: 'Your card is blocked. Please pay the past due amount of {amount} to re-enable your card.',
                },
                withLateFeeReversalOffer: {
                    title: 'Account Past Due',
                    description: 'Please pay the past due amount of {amount} today and we will reverse late fees',
                },
                cta: 'Make A Payment',
                getHelp: 'Get Help',
            },
            overLimit: {
                title: 'Card Limit Exceeded',
                description: 'Your balance is currently over the limit. Your card is currently disabled.',
            },
            fraud: {
                title: 'Fraud Detected',
                description: 'For your security, we have frozen your card from making any further purchases and balance transfers.',
            },
            blocked: {
                title: 'Account Blocked',
                description: 'Your card has been blocked from making any further purchases and balance transfers.',
            },
            closed: {
                title: 'Account Closed',
                description: 'Your account has been closed.',
            },
            ctaViewDetails: 'View Details',
            pendingClose: {
                title: 'Account Pending Close',
                description: 'Your card is pending to be closed and will no longer function.',
            },
            pendingPayoffThenLimitedMode: {
                title: 'Pending Lien Removal',
                description: 'The lien is pending removal, and then your card will be transitioned to the {limitedModeCreditLimit} limited mode.',
            },
            limitedMode: {
                title: '{limitedModeCreditLimit} Limited Mode',
                description: 'Easily upgrade to a larger line size again by re-attaching your home.',
                cta: 'Re-attach Home',
            },
            homeReAttachInProgress: {
                title: 'Continue Upgrading',
                description: 'Continue upgrading to a larger line size again by re-attaching your home.',
                cta: 'Finalize',
            },
            homeReAttachInRescission: {
                title: 'Cancellation Period',
                description: 'Due to increasing your line and govt. regulations, we must wait 3 days until you can access your funds. Your waiting period ends on {rescissionEndDate}.',
                cta: '',
            },
            expiringFloodInsurance: {
                title: 'Flood Insurance Expiring',
                description:
                    'Your flood insurance policy expires on {floodExpiryDate}. Please submit your renewed flood insurance policy or proof of payment by {floodExpiryDate} to keep your account active.',
                cta: 'Submit Renewal',
            },
            expiredFloodInsurance: {
                title: 'Flood Insurance Expired',
                description: 'Your flood insurance policy has expired. Please submit your renewed flood insurance policy or proof of payment to make your account active.',
                cta: 'Submit Renewal',
            },
            paymentProcessingAfterPastDue: {
                title: 'Payment Being Processed',
                description: 'Thank you for your payment. It may take until {dqPaymentProcessEstimate} to clear. Your card has been blocked until payment clears.',
            },
        },
        accountActivationBanner: {
            incompleteUccFlow: {
                title: 'Welcome to Aven!',
                description: 'To activate your card, please verify your stated income.',
                cta: 'Verify Income',
            },
            incompleteNaturalDisasterVideoAndLocationReview: {
                title: 'Home Verification Pending',
                description: 'To activate your card, please verify your home with a video walkthrough.',
                cta: 'Continue Verification',
            },
            priorLienRelease: {
                title: `Refinance In Progress`,
                description: `We're waiting for your previous lender to release the lien, which usually takes 14-21 days after opening your Aven account. Once released, you can activate your card.`,
                cta: `Have a Question?`,
            },
            fastFundingPending: {
                title: `Let's Get Started!`,
                // This is embedded in a native <p> element in AccountActivationBanner.vue
                // description: `You qualify for early access to {fastFundingAmount} before your card arrives! Already have it, <a class='text-underline' href='${RoutePaths.CARD_ACTIVATION}'>activate now</a>.`,
                cta: 'Early Access to {fastFundingAmount}',
            },
            fastFundingSubmitted: {
                title: 'Activate Card',
                descriptionActivateGreaterLineSize: 'Please activate your new card as soon as you receive it in the mail to unlock your entire {lineSizePostCardActivation} line.',
                descriptionActivateEqualLineSize: 'Please activate your new card as soon as you receive it in mail to use it for purchases and cashback.',
                cta: 'Activate Card',
            },
            cashOutUpfrontNow: {
                title: 'Activate Card',
                description: "Let's wrap things up by sending {cashOutUpfrontAmount} to your bank account",
                cta: 'Cash Out Now',
                secondaryCta: 'Balance Transfer Instead',
            },
            cashOutUpfrontInProgress: {
                title: 'Pending Card Activation',
                description: 'Your Cash Out is in process. Your card will be activated once completed',
            },
            activate: {
                title: 'Activate Card',
                description: 'Please activate your new card as soon as you receive it in the mail.',
                cta: 'Activate Card',
            },
        },
        cashV2Banner: {
            cashOutSubmitted: {
                title: 'Cash Out Submitted',
                subTitle: 'Your {cashOutUpfrontAmount} Cash Out will be processed on {formattedCashOutCompletionDate}.',
            },
            cashOutTransferring: {
                title: 'Cash Out Transferring',
                subTitle: '{cashOutUpfrontAmount} was sent to your bank. It should be available by {formattedCashOutCompletionDate}.',
            },
        },
        balanceTransfer: {
            empty: {
                title: 'Balance Transfer',
                subTitle: 'Transfer balances from high-interest accounts and stop paying so much in interest. You can also transfer loans with our Cash Out feature.',
            },
            linkedCards: {
                sectionHeader: 'LINKED CARDS',
            },
            pendingTransfer:
                'We have sent the request to your destination account. It can take 1-7 business days to reflect on your account. If you do not see it after that, please contact your account.',
            error: {
                title: 'Balance Transfer Error',
                genericError:
                    'An error came up while processing your Balance Transfer request. Our team has been notified. Please refresh the page and try again. If this problem persists, please reach out to our support team for assistance with your Balance Transfer.',
                invalidAccountNumberFail: {
                    title: 'Credit Card Error',
                    subTitle: 'Sorry, the credit card number you entered could not be validated. Please try again or reach out to our support team.',
                },
                btForbiddenToCustomer:
                    'Unfortunately, Balance Transfers can only be initiated by the primary cardholder or co-applicant. Please have the primary cardholder or co-applicant perform the Balance Transfer.',
                invalidAmountFail: 'The amount you have entered is invalid. Please correct the amount and submit your Balance Transfer again.',
                bankNotSupported:
                    'Sorry, we do not currently support balance transfers from this credit card. Instead, you can use our Cash Out feature to send money to your bank account to pay off your card.',
                duplicateRequestError: {
                    title: 'Balance Transfer Exists',
                },
                duplicateBalanceTransferError: 'Sorry, you have already made a balance transfer of the same amount recently. Please double check that this is not a duplicate balance transfer.',
            },
            loadingTitle: 'Submitting...',
            accountList: {
                title: 'Transfer From...',
                otherAccountTitle: 'New Account',
            },
            iframe: {
                titleOther: 'New Account',
                ccOption: 'Credit Card',
                plOption: 'Personal Loan',
            },
            cardNotActivated: {
                title: 'Balance Transfer',
                body: 'For your security, please activate your card before you transfer. If your card is still in the mail, please wait until you get the card.',
                cta: 'Activate Now',
            },
            noAvailableCredit: {
                title: 'Balance Transfer',
                body: 'You currently don’t have any available credit. Once there is available credit, you will be able to make a transfer.',
            },
            instantSelect: {
                methodFiTos: {
                    title: 'Balance Transfer',
                    body: 'Transfer balances from other credit cards and personal loans in minutes and stop paying so much interest. Just a low one time {formattedTransferFee} transfer fee.',
                    noFeeBody: 'Transfer balances from other credit cards and personal loans in minutes and stop paying so much interest. No transfer fee.',
                    cta: 'Balance Transfer Now',
                    disclosure: `By clicking this button, you authorize Aven and Method Financial to access and transmit your personal, credit, and financial data from your financial institutions and make a soft pull of your credit report.`,
                    acceptingMethodFiTosLoadingMessage: 'Loading... this may take a minute',
                },
                duringOnboarding: {
                    title: 'Save money by transferring your high interest debt to Aven',
                    skip: 'Skip For Now',
                },
                selectHeader: 'Pick one to get started',
                amountHeader: 'Amount',
                selectorTitle: 'Balances To Transfer',
                availableCreditTitle: 'Available On Aven',
                transferAmountTitle: 'Transfer Amount',
                transferFeeTitle: '{fee} Fee',
                transferTotalTitle: 'Added To Balance',
                continueCta: 'Continue',
                inputPlaceholder: '$0.00',
                clickHere: 'Click here',
                ifYouDontSeeYourCard: 'if you don’t see your credit card listed above.',
                showMore: 'Show More Accounts',
                recurringTransferHeader: 'SUBSCRIBED CARDS',
                nextTransferOn: 'Next Transfer {transferDate}',
            },
            recurring: {
                genericError: 'We were not able to subscribe this account to repeat balance transfers. Our team has been notified.',
                successfulSubscribe: {
                    title: 'CARD SUBSCRIBED',
                    body: `Your next transfer will be on {dateString} - no transfer fees, no additional fees. We'll send a confirmation a day prior.`,
                },
                settings: {
                    title: 'Subscription Details',
                    nextTransferLabel: 'Next Transfer',
                    amountLabel: 'Amount',
                    amountNonePlaceholder: 'Waiting on Statement',
                    fromCardLabel: 'From',
                    transferFeeLabel: 'Transfer Fee',
                    transferFee: '0%',
                    unsubscribeCta: 'Unsubscribe Card',
                    areYouSureText: 'Are you sure you want to unsubscribe {cardName}? Subscribed cards have no transfer fees.',
                    keepSubscriptionCta: 'Keep Subscription',
                    unsubscribeConfirmCta: 'Unsubscribe',
                    errorUnsubscribing: 'There was an error unsubscribing from {cardName}. Please try again later.',
                },
                editAmount: {
                    title: 'Edit Transfer Amount',
                    amountLabel: 'Transfer Amount',
                    amountPlaceholder: '$0.00',
                    cta: 'Update Amount',
                    genericError: `We couldn't update the amount. Please make sure you're connected to the internet and try again.`,
                },
            },
            confirmation: {
                title: 'Transfer initiated!',
                receivedTitle: 'Transfer received!',
                subTitle: 'Your balance transfer of {amount} from your {accountName} ***{last4OfTargetCard} was received. This usually takes 7 business days to reflect on your other card.',
                instantSubTitle:
                    'Your balance transfer of {amount} from your {accountName} ***{last4OfTargetCard} has initiated and usually takes 1-2 business days to complete. It may take 5-10 business days to appear on your {accountName} account.',
                instantReceivedSubTitle:
                    "Your balance transfer of {amount} from your {accountName} ***{last4OfTargetCard} was received and will start the next business day. We’ll notify you when it's underway.",
                instantFmpSweepSubTitle:
                    'Your balance transfers totaling {amount} will be transferred from {numberOfCards} of your credit cards. It will take 1-2 business days to initiate and may take 5-10 business days to reflect on your other cards.',
                doneCta: 'Done',
                transferOtherAccounts: 'Transfer Other Accounts',
                initiateTransferCta: 'Initiate Transfer',
            },
            summary: {
                title: 'Card Balance Transfer',
                subTitle: 'A Balance Transfer of <b>{amount}</b> from your <b>{accountName} ending in {last4OfTargetCard}</b> will be reviewed and initialized once approved.',
                instantBtSubtitle: 'We will transfer a balance of <b>{amount}</b> from your <b>{accountName} ending in {last4OfTargetCard}</b> and move the balance to your Aven Card.',
                transferAmountTitle: 'Transfer From ***{last4OfTargetCard}',
                transferFeeTitle: '{fee} Fee',
                transferTotalTitle: 'Added To Balance',
                confirmTransferCta: 'Confirm Transfer',
            },
            restricted: {
                title: 'Balance Transfer',
                secondaryNotAllowed: 'Unfortunately, balance transfers can only be initiated by the primary cardholder. Please have the primary cardholder perform the balance transfer.',
            },
            inRescission: {
                title: 'Balance Transfer',
                stillInRescission: 'Sorry, you are currently within your 3-day rescission period. Please try again after {rescissionEndDate}.',
            },
            promo: {
                title: 'Get Paid to Balance Transfer',
                body: 'Complete a balance transfer to your Aven card by April 30th and get 1% back',
                cta: 'Start Balance Transfer',
            },
            balanceTransferDailyLimitInfo: `For security reasons, there is a ${generic.prettyPrintDollarAmount(
                String(MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS)
            )} daily maximum for combined Cash Outs and Balance Transfers.`,
            balanceTransferCreditLimitInfo: 'The maximum amount is based on your available credit.',
            balanceTransferAutoPayStatementBalanceWarning: `<b>Note</b>: You have AutoPay set to pay the full Statement Balance, which will include this full balance transfer on your next statement. <a class='text-underline' href='/autoPay' target='_blank'>Update AutoPay Settings</a> if you do not want to pay it off in full next statement.`,
        },
        featureNotAvailable: {
            title: 'Feature Not Available',
            subTitle: 'Sorry, the current feature is not available because your account is not current. Contact us if you have any questions.',
            subTitleExpiredFloodInsurance:
                'Sorry, the current feature is not available because your flood insurance is expired. Please submit your renewed flood insurance policy or proof of payment to reactivate this feature.',
            contactUsBtnCta: 'Contact Us',
            submitFloodInsuranceBtnCta: 'Submit Renewal',
        },
        balanceTransferOrCashOutInfo: {
            paybackDetails: 'Payback Details',
            monthlyBreakdown: 'Monthly Breakdown',
            pending: 'Pending',
            completed: 'Completed',
            reversal: 'Refunded',
            canceled: 'Canceled',
            status: 'Status',
            from: 'From',
            dateInitiated: 'Date Initiated',
            amount: 'Amount',
            fee: 'Fee',
            totalRemaining: 'Total Remaining',
            method: 'Method',
            startDate: 'Start Date',
            termLength: 'Term Length',
            principal: 'Principal',
            fixedFinanceCharge: 'Fixed Finance Charge',
            totalMonthlyPayment: 'Total Monthly Payment',
            fixed: 'Fixed Monthly Payment',
            revolving: 'Standard Revolving',
            apr: 'APR',
        },
        amortizationLoanChoice: {
            amortizationOptionsSectionHeader: 'Payback Method',
            amortizationOptionsStandardText: 'Standard Credit Card Revolving Plan',
            amortizationOptionsStandardSubText: ({ named }) =>
                `<p class="small text-muted"> 
                    Pay a minimum of 1% of principal each month plus interest at your current Aven variable rate of ${named('aprPercent')}.
                </p>`,
            amortizationOptionsStandardSubTextSub700Fico: ({ named }) =>
                `<p class="small text-muted">
                    Your estimated first full minimum monthly payment is $${named('firstFullMonthPayment')} 
                    at a ${named('aprPercent')} rate ($${named('firstMonthPrincipalPayment')} principal + $${named('firstMonthInterestPayment')} interest).
                </p>`,
            amortizationOptionsTeaserPromoSubText: ({ named }) =>
                `<p class="small text-muted">
                    Pay a minimum of 1% of principal each month plus your standard Aven variable rate interest of ${named('promoAprPercent')}
                    APR, and then ${named('aprPercent')} APR after the intro period. 
                    For example if your balance is ${named('aggrAmount')}, <b>1% will be ${named('minPay')}</b>.
                </p>`,
            amortizationOptionsTeaserPromoSubTextSub700Fico: ({ named }) =>
                `<p class="small text-muted">
                    Your estimated first full month payment after the intro ${named('promoAprPercent')} APR period 
                    would be <b>$${named('firstFullMonthPayment')}</b> 
                    (1% of ${named('aggrAmount')} + Interest at var. APR ${named('aprPercent')}).
                    The initial payment might be lower because of a partial month.
                </p>`,
            amortizationOptionsTermSubTextWithFee:
                'Get predictable monthly payments using a fixed monthly finance charge and a rate fixed at {fixedInstallmentApr}. This is calculated using your current Aven APR + a {fixedRateAprPremium} premium to lock in the rate.',
            amortizationOptionsTermText: 'Fixed Monthly Payments',
            amortizationOptionsTermSubText: 'Get predictable monthly payments using a fixed monthly finance charge and no interest.',
            amortizationOptionTitle: '{yearsAndMonths}',
            amortizationOptionSubTitle: '{numPayments} Payments',
            amortizationOptionsSubText1: 'Principal',
            amortizationOptionsSubText2: 'Fixed Finance Charge',
        },
        simpleInterestLoanChoice: {
            simpleInterestOptionsTermSubText: `Get predictable monthly payments at a fixed rate of {fixedRateApr}. This is calculated using your current Aven APR + a {fixedRateAprPremium} premium to lock in the rate.`,
            simpleInterestApr: `{apr} Fixed Rate`,
            simpleInterestTotalPaid: '{totalPrincipalPlusInterest} Paid',
        },
        fixedTermUserLoanChoice: {
            optionTermSubText: `Get predictable monthly payments at a fixed rate starting at {fixedRateApr}.`,
        },
        learnMore: {
            graduationCapAlt: 'Graduation Cap Icon',
            title: 'Learn More',
        },
        monthlyPayment: {
            title: 'What is my monthly payment?',
        },
        last4Digit: {
            title: 'Don’t recognize the last 4 digits?',
            subTitle: `It's probably because you recently received a replacement card or have multiple virtual card numbers. Check the balance to make sure it is similar.`,
        },
        commonActions: {
            cardBalance: 'Balance<br>Transfer',
            cashOut: 'Cash Out<br>To Bank',
            makePayment: 'Make A<br>Payment',
        },
        securedPasswordCheck: {
            title: 'Confirm your password',
            incorrectPassword: 'Incorrect Password',
        },
        securedSsnCheck: {
            title: 'Confirm the last 4 digits of your social security',
            ssnInvalid: 'Please check the last 4 digits of your social security number',
        },
        transactionDisputeQuestionnaire: {
            merchant: 'Merchant',
            amount: 'Amount',
            chName: 'Made By',
            problemDetailsSectionHeader: 'Problem Details',
            merchantRecognitionQuestion: 'Do you recognize the merchant above?',
            cardPossessionQuestion: 'Is the card in your possession?',
            cardLostDateQuestion: 'When did you lose your card?',
            datePlaceholder: 'MM/DD/YYYY',
            explanationQuestion: 'Type a brief explanation of why you are disputing the transaction.',
            uploadDocumentsQuestion: 'Upload copies of emails and receipts from your communication with the merchant to resolve your issue.',
            addSupportingDocumentLabel: 'Add Supporting Document',
            addSecondarySupportingDocumentLabel: 'Add More (Optional)',
            visaConsent: 'By checking this box, I give consent to Aven to investigate this dispute. Aven will share my contact information with VISA and the merchant.',
            serviceReceivedDate: 'When did you receive the merchandise/service?',
            detailsOfPurchaseQuestion: 'Provide details of what was ordered.',
            typeOfPurchaseQuestion: 'What was purchased?',
            disputeAmountQuestion: 'Enter the amount you’re disputing.',
        },
        feedbackBox: {
            title: 'Questions or thoughts?',
            subTitle: 'We are here to help. Please send us a message or comment.',
            sentResponse: 'Thank you for your feedback! It really helps us build a better product for you.',
            placeholder: 'Type your message...',
        },
        superPifCard: {
            title: 'You Got a {formattedPifAmount} Super Gift',
            subtitle: 'Send this gift to a friend or neighbor, and we will give them {formattedPifAmount} in credit. They may share part of it back with you!',
            button: 'Share {formattedPifAmount} – On Us',
        },
        pifFooter: {
            classicPif: {
                content:
                    'Payment of the signing bonus to the Recipient is contingent upon the activation of the Recipient’s Aven Account. The signing bonus will be issued as statement credit within 5 business days of the activation. For more information, please see our <a href="https://www.aven.com/public/docs/PifTerms" target="_blank">PIF Terms and Conditions.</a>',
            },
            travelRewardsV0: {
                content:
                    'Payment of the signing bonus to the Recipient is contingent upon the activation of the Recipient’s Aven Account. The signing bonus is granted to the Recipient, not the existing Aven cardholder who is sharing their PIF code. For more information, please see our <a href="https://www.aven.com/public/docs/PifTravelTerms" target="_blank">PIF Terms and Conditions.</a>',
            },
            travelTicketsBahamas: {
                content:
                    'Payment of the signing bonus to the Recipient is contingent upon the activation of the Recipient’s Aven Account. The signing bonus is granted to the Recipient, not the existing Aven cardholder who is sharing their PIF code. For more information, please see our <a href="https://www.aven.com/public/docs/PifTravelTerms" target="_blank">PIF Terms and Conditions.</a>',
            },
        },
        estimatedSavingsCard: {
            title: 'Interest Saved',
            balanceTransferAdditionalAmount: `+{formattedDollarAmount} With Balance Transfer`,
            cashOutAdditionalAmount: `+{formattedDollarAmount} With Cash Out`,
            amountLabelTop: 'Estimated Yearly Savings',
            amountLabelBottom: 'Vs. Keeping Avg. Credit Card<sup style="font-size: 0.75rem; top: 0">*</sup>',
            yearLabels: {
                now: 'Now',
                one: '1 Yr',
                two: '2 Yrs',
                three: '3 Yrs',
                four: '4 Yrs',
            },
            inviteButtonCta: 'Invite Friends to Save',
            calculationToggleCta: 'How is this calculated?',
            calculationTable: {
                lastStatementBalanceLabel: 'Your Current Balance',
                yearlyInterestAvgCardLabel: 'Yearly Interest (Avg. Card)',
                yearlyInterestAvenCardLabel: 'Yearly Interest (Aven Card)',
                yearlyEstimatedSavingsLabel: 'Estimated Savings<sup style="font-size: 1rem; top: 0">*</sup>',
            },
            footnote: `*Source: "{articleName}" <a href="{linkUrl}" target='_blank' rel='noreferrer noopener'>{linkText}</a> on {dateUpdated} showing the average rate for credit cards in the US for people with good credit is {formattedGoodCreditApr} APR.`,
        },
    },
    header: {
        question: 'Need Help?',
        description: 'If you have any questions or comments, please feel free to text us.',
        desktopDescription: `If you have any questions or comments, please feel free to text us at <a href='sms:${contact_info.sms}'>${contact_info.smsFormatted}</a>.`,
        sendText: 'Send Text', //`<a class="text-white" href='tel:${contact_info.phone}'>Call</a>`,
        help: 'Help',
    },
    shareContactsModal: {
        title: 'Contact Permission',
        body: 'The Aven app saves and stores your contacts on our servers to help you calculate rewards and generate a personal invite code to help you invite your friends and to confirm your identity even when the app is closed.<br /><br />We also save your contacts to Aven servers as part of the identity verification process to help you access your funds as fast as possible for Aven’s fast funding program.',
        allow: 'Agree',
        deny: 'Skip',
    },
    appStoreReviewModal: {
        title: 'Are you loving your Aven experience?',
        allow: 'Yes',
        deny: 'No',
    },
    dashBoard: {
        call: `<a href='tel:${contact_info.phone}' style="color: black">${contact_info.dashFormatted}</a>`,
    },
    global: {
        // Page not found
        pageNotFound: {
            header: 'Page not found',
            subHeader: 'Sorry, but the page you were looking for does not seem to exist. To apply, visit the link below.',
            cta: 'Get started',
        },
        loadingMessage: {
            loading: 'Loading...',
            verifying: 'Verifying...',
            saving: 'Saving...',
        },
        cta: {
            continue: 'Continue',
            gotIt: 'Got It!',
            letsGo: `Let's Go`,
            next: 'Next',
            goBack: 'Go Back',
            refresh: 'Refresh',
            retry: 'Retry',
            goToLogin: 'Go To Login',
            dismiss: 'Dismiss',
            done: 'Done',
            setupAutoPay: 'Set Up AutoPay',
            notNow: 'Not Now',
            forgotPassword: 'Forgot Password',
        },
        idleWarning: {
            title: 'Idle Warning',
            message: 'Your session is about to expire in 30 seconds due to inactivity',
            stay: 'Stay',
            logout: 'Logout',
        },
        errors: {
            title: 'Something Went Wrong',
            generic: 'Sorry, there was a problem with the network. Please try again.',
            genericRetry: 'Something went wrong. Please try again.',
            badRequest: 'An error came up while processing your information! Please review that the information you submitted is correct. You could also try refreshing the page. Thanks!',
            confirmAddress: 'Could not confirm address. Please check your entry',
            serverError: 'Oh no, something went wrong. Our team has been notified.',
            biometricsGenericError: 'Please sign in to enable biometrics',
            adBlockMessage: 'We understand your need for privacy. However, ad blockers will interfere with our application. Please disable it and refresh the page.',
        },
    },
    customValidationRules: {
        noQuotesAllowed: 'Single quote and double quotes are not allowed',
        twilioCode: 'Code must be exactly 6 digits',
        notValidPhoneNumber: 'Not a valid phone number',
        invalidCurrency: 'Please enter a valid amount',
        currencyTooSmall: 'Minimum allowed amount is {minAmount}',
        currencyTooLarge: 'Maximum allowed amount is {maxAmount}',
        pointsTooSmall: 'You can redeem a minimum of {minAmount} points',
        pointsTooLarge: 'You currently only have {maxAmount} points',
        maxAllowedByACI: `Maximum allowed is {maxAllowedPerDay} per day. To make a larger payment, please do a wire transfer.`,
        maxAllowedByACITodayMultiPayments: `Maximum allowed is {maxAllowedPerDay} per day. You have {amountRemainingToday} remaining allowed today. To make a larger payment, please do a wire transfer.`,
        minAllowedByACI: `Minimum allowed is $${MIN_PAYMENT_AMOUNT_ALLOWED_BY_ACI_IN_DOLLARS}`,
        confirmNoBusinessAccountManualCashout: 'Business accounts are currently not supported via a manual upload. Please use a personal checking or savings account or use "link account" instead.',
        passwordMismatch: "The passwords you entered don't match",
        passwordRequirements: 'Password must be at least 8 characters, with at least 1 number and 1 special character.',
        invalidDateFormat: 'Invalid date',
    },
    validation: {
        alpha: 'May only contain alphabetic characters',
        alpha_num: 'May only contain alpha-numeric characters',
        alpha_dash: 'May contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'May only contain alphabetic characters as well as spaces',
        between: 'Must be between {min} and {max}',
        confirmed: 'Confirmation does not match',
        digits: 'Must be numeric and exactly contain {length} digits',
        dimensions: 'Must be {width} pixels by {height} pixels',
        email: 'Must be a valid email',
        excluded: 'Is not a valid value',
        ext: 'Is not a valid file',
        image: 'Must be an image',
        integer: 'Must be an integer',
        length: 'Must be {length} characters long',
        max_value: 'Must be {max} or less',
        max: 'May not be greater than {length} characters',
        mimes: 'Must have a valid file type',
        min_value: 'Must be {min} characters or more',
        min: 'Must be at least {length} characters',
        numeric: 'May only contain numeric characters',
        oneOf: 'Is not a valid value',
        regex: 'Format is invalid',
        required_if: 'This field is required',
        required: 'This field is required',
        size: 'Size must be less than {size}KB',
    },
    accessibility: {
        error: 'Error',
        close: 'Close',
        loading: 'Loading',
        back: 'Back',
        clear: 'Clear',
        active: 'Active',
        transfer: 'Transfer',
        party: 'Party Emoji',
    },
    sharePifLink: {
        classicPif: {
            body: `Hey! I've been using the Aven Card – a card for homeowners – and love it. If you join using my code, you get {maxAmount} in credit. Go to {pifLink} to get started.`,
            subject: `Get {maxAmount} in credit if you join Aven!`,
        },
        travelRewardsV0: {
            body: `Hey! I've been using the Aven Card – a card for homeowners – and love it. If you join using my code, you get two free tickets. Go to {pifLink} to get started.`,
            subject: `Two Free Tickets to the Las Vegas!`,
        },
        travelTicketsBahamas: {
            body: `Hey! I've been using the Aven Card – a card for homeowners – and love it. If you join using my code, you get two free tickets to the Bahamas. Go to {pifLink} to get started.`,
            subject: `Two Free Tickets to the Bahamas!`,
        },
        estimatedSavings: {
            classicPif: {
                body: `I'm saving {estimatedSavingsAmount} with the Aven credit card! Join with my code for {maxAmount} credit. Go to {pifLink} to get started.`,
            },
            travelRewardsV0: {
                body: `I'm saving {estimatedSavingsAmount} with the Aven credit card! Join with my code for two free tickets. Go to {pifLink} to get started.`,
            },
            travelTicketsBahamas: {
                body: `I'm saving {estimatedSavingsAmount} with the Aven credit card! Join with my code for two free tickets. Go to {pifLink} to get started.`,
            },
        },
    },
    twilioError: {
        generic: 'Sorry, Something went wrong. Please try again later.',
        60200: 'Invalid confirmation code',
        20404: 'Confirmation code has expired.',
        60202: 'Please wait 30 minutes, then request a new confirmation code.',
        60203: 'Too many codes requested. Please try again after 10 minutes.',
        60212: 'Max number of attempts reached. Please try again in 30 minutes.',
    },
}
