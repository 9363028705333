import { Logger, inspect } from 'aven_shared/src/utils/logger'
import { appSessionStorage, localStorageKey } from './storage'
import { useDeviceInfoStore } from '../store/deviceInfoStore'
import { useSessionStore } from '../store/sessionStore'

const getMetadata = (): Record<string, string> => {
    const sessionId = useSessionStore().sessionId
    const deviceInfoStore = useDeviceInfoStore()

    const metadata: Record<string, string> = {
        deviceModel: deviceInfoStore.deviceModel,
        browser: deviceInfoStore.browser,
        resolution: deviceInfoStore.resolution,
        os: deviceInfoStore.os,
        appVersion: deviceInfoStore.appVersion,
        runtimeType: deviceInfoStore.runtimeType,
        platform: deviceInfoStore.platform,
        deviceCapabilities: JSON.stringify(deviceInfoStore.deviceCapabilities),
        isWebView: `${deviceInfoStore.isWebView}`,
        isSingleWebView: `${deviceInfoStore.isSingleWebView}`,
    }
    const applicantId = appSessionStorage.getItem(localStorageKey.applicantId)
    if (applicantId) {
        metadata.applicantId = applicantId
    }
    const loanApplicationId = appSessionStorage.getItem(localStorageKey.loanApplicationId)
    if (loanApplicationId) {
        metadata.loanApplicationId = loanApplicationId
    }
    if (sessionId) {
        metadata.sessionId = sessionId
    }
    const sessionRecordingUrl = appSessionStorage.getItem(localStorageKey.sessionRecordingUrl)
    if (sessionRecordingUrl) {
        metadata.sessionRecordingUrl = sessionRecordingUrl
    }

    const creditCardCustomerId = appSessionStorage.getItem(localStorageKey.creditCardCustomerId)
    if (creditCardCustomerId) {
        metadata.creditCardCustomerId = creditCardCustomerId
    }

    return metadata
}

const logger = new Logger({
    avenProject: 'aven-my',
    sentryDSN: process.env.VUE_APP_SENTRY_ID ?? '',
    logProcessorBaseUrl: process.env.VUE_APP_LOGGER_BASE_URL,
    clientSideLogsEnabled: process.env.VUE_APP_CLIENT_SIDE_LOGS_ENABLED === 'yes',
    getMetadata,
})

export { logger, inspect }
